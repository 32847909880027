import { styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'LuggageEditWrapper' })(
  ({ theme }) => ({
    overflow: 'hidden',
    padding: '24px 32px'
  })
);

export const LuggageWrapper = styled('div', {
  name: 'LuggageWrapper',
  shouldForwardProp: prop => prop !== 'submitting'
})<{ submitting?: boolean }>(({ theme, submitting }) => ({
  padding: '8px 16px',
  gap: '8px',
  borderRadius: '24px',
  backgroundColor: theme.palette.neutral.light3,
  width: 'fit-content',
  ...(submitting && {
    opacity: 0.5
  })
}));

export const LuggageItemText = styled('div', { name: 'LuggageItemText' })(
  ({ theme }) => ({
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textAlign: 'center'
  })
);

import EmptyCrewTable from './EmptyCrewTable';
import CrewTableItem from './Item';
import CrewTableItemSkeleton from './Item/LoadingSkeleton';
import { CrewListWrapper } from './styled';

export default function CrewTableBlock({
  data,
  loadData,
  columnNameWidth,
  children
}) {
  return (
    <CrewListWrapper>
      {data.loading && !data?.next_cursor ? (
        Array(20)
          .fill(0)
          .map((_, index) => <CrewTableItemSkeleton key={index} />)
      ) : data?.data.length ? (
        data?.data.map((item: number, index: number) => (
          <CrewTableItem
            key={index}
            item={item}
            columnNameWidth={columnNameWidth}
            loadData={loadData}
          />
        ))
      ) : (
        <EmptyCrewTable />
      )}
      {children}
    </CrewListWrapper>
  );
}

import ClientInFlightDialog from '@common/dialog/ClientInFlightDialog';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import { FormikHelpers } from 'formik';
import { get } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { put } from 'redux-saga/effects';

const DIALOG_ERROR = {
  clientInFlight: {
    component: ClientInFlightDialog,
    title: 'Client deletion prohibited',
    cancelButtonLabel: 'Close'
  }
};

export default function* handleActionError(
  error: any,
  formik?: FormikHelpers<any>
) {
  yield put({ type: '@loading/hide' });
  if (!error) return;
  const message =
    get(error, 'response.data.message') ??
    get(error, 'response.data.error') ??
    'Some errors occurred';
  if (get(error, 'response.data.data.type') === 'form' && formik) {
    formik.setErrors(get(error, 'response.data.data.form'));
    return;
  }

  if (get(error, 'response.data.data.type') === 'dialog') {
    yield DialogJSX.open({
      component: DIALOG_ERROR[get(error, 'response.data.data.name')].component,
      props: {
        title: DIALOG_ERROR[get(error, 'response.data.data.name')].title,
        cancelButtonLabel:
          DIALOG_ERROR[get(error, 'response.data.data.name')].cancelButtonLabel,
        errorData: get(error, 'response.data.data')
      }
    });
  } else if (error?.response?.status === 401) {
    return;
  } else {
    yield enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: 1500
    });
  }
}

import { SxProps, styled } from '@mui/material/styles';
import classNames from 'classnames';
import React, { ForwardedRef } from 'react';

export interface LineIconProps {
  icon: string;
  component?: string;
  className?: string;
  color?:
    | 'primary'
    | 'black'
    | 'white'
    | 'accent'
    | 'danger'
    | 'warning'
    | 'positive'
    | 'lightAccent'
    | string;
  onClick?: (props?: any) => void;
  style?: any;
  haveBackground?: boolean;
  sx?: SxProps;
  size?: 'sm' | 'md' | 'lg';
}

const StyledIcon = styled('span', {
  name: 'MuiIcon',
  slot: 'root',
  shouldForwardProp: prop => prop !== 'haveBackground'
})<{ haveBackground?: boolean }>(({ theme, haveBackground }) => ({
  fontFamily: 'Avıonexus',
  speak: 'none',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontVariant: 'normal',
  display: 'inline-block',
  textTransform: 'none',
  fontSmoothing: 'antialiased',
  WebkitFontSmoothing: 'antialiased',
  ...(haveBackground && {
    padding: '4px',
    borderRadius: '100%'
  })
}));

export default React.forwardRef(
  (
    {
      component = 'span',
      icon = 'ico-down',
      className,
      size = 'md',
      color = 'inherit',
      haveBackground = false,
      ...props
    }: LineIconProps,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    return (
      <StyledIcon
        ref={ref}
        className={classNames(
          className,
          icon,
          'icon',
          `icon-${size}`,
          `icon-${color}`,
          { haveBackground }
        )}
        haveBackground={haveBackground}
        {...props}
      />
    );
  }
);

import Icon from '@common/components/Icon';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LuggageItemText, LuggageWrapper } from './styled';

export default function LuggageItem({ data }: { data: Record<string, any> }) {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState();
  const handleDelete = () => {
    dispatch({
      type: '@flight/deleteLuggagePax',
      payload: {
        lugId: data.luggage_id,
        setSubmitting
      }
    });
  };
  return (
    <LuggageWrapper className="flex-center-rows" submitting={submitting}>
      <LuggageItemText>
        {data?.type?.luggage_name} - {data.weight} kg
      </LuggageItemText>
      <IconButton
        size="small"
        color="error"
        onClick={handleDelete}
        disabled={submitting}
      >
        <Icon size="sm" icon={'icon-trash-fill'}></Icon>
      </IconButton>
    </LuggageWrapper>
  );
}

import { styled } from '@mui/material';

export const Wrapper = styled('div', {
  name: 'StatusWrapper'
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.neutral.light2}`,
  borderRadius: '8px'
}));
export const IconWrapper = styled('div', {
  name: 'IconWrapper'
})(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '4px',
  width: 'fit-content',
  height: '40px',
  marginRight: theme.spacing(1.5)
}));

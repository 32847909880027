import Icon from '@common/components/Icon';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  styled,
  tooltipClasses
} from '@mui/material';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 366
  }
});

export const Status = ({
  type,
  titleTooltip
}: {
  type: 'success' | 'failed' | 'warning' | 'allergy' | 'none';
  titleTooltip?: any;
}) => {
  if (type === 'success') {
    return (
      <Tooltip arrow title={titleTooltip}>
        <Icon
          icon="icon-check-circle-fill"
          size="lg"
          sx={{
            p: 0.5,
            color: 'positive.dark1',
            backgroundColor: 'positive.light3',
            borderRadius: '50%',
            ':hover': {
              boxShadow: `inset 0px 0px 0px 1px`
            }
          }}
        />
      </Tooltip>
    );
  }
  if (type === 'failed') {
    return (
      <Tooltip arrow title={titleTooltip}>
        <Icon
          icon="icon-X-circle-filled"
          size="lg"
          sx={{
            p: 0.5,
            color: 'accent.light1',
            backgroundColor: 'accent.light3',
            borderRadius: '50%',
            ':hover': {
              boxShadow: `inset 0px 0px 0px 1px`
            }
          }}
        />
      </Tooltip>
    );
  }
  if (type === 'warning') {
    return (
      <Tooltip arrow title={titleTooltip}>
        <Icon
          icon="icon-error"
          size="lg"
          sx={{
            p: 0.5,
            color: 'warning.dark1',
            backgroundColor: 'warning.light2',
            borderRadius: '50%',
            ':hover': {
              boxShadow: `inset 0px 0px 0px 1px`
            }
          }}
        />
      </Tooltip>
    );
  }
  if (type === 'allergy') {
    return (
      <Tooltip arrow title={titleTooltip}>
        <Icon
          icon="icon-allergy"
          size="lg"
          sx={{
            p: 0.5,
            color: 'accent.main',
            backgroundColor: 'danger.light2',
            borderRadius: '50%',
            ':hover': {
              boxShadow: `inset 0px 0px 0px 1px`
            }
          }}
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip arrow title={titleTooltip}>
      <Icon
        icon="icon-X-circle-filled"
        size="lg"
        sx={{
          p: 0.5,
          color: 'neutral.main',
          backgroundColor: 'neutral.light3',
          borderRadius: '50%',
          ':hover': {
            boxShadow: `inset 0px 0px 0px 1px #888F97`
          }
        }}
      />
    </Tooltip>
  );
};

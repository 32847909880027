import * as Yup from 'yup';

export const InviteCrewSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('The email field is required')
    .max(250, 'Exceeds max length. Please reduce input size'),
  firstName: Yup.string().max(50, 'Exceeds max length. Please reduce input size'),
  lastName: Yup.string().max(50, 'Exceeds max length. Please reduce input size'),
  crewTypeId: Yup.number().required('The crew type field is required')
});

import { entitySelector } from '@core/selector/entitySelector';
import { mapArrayByReference } from '@helper/stringFunction';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { get } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFadeScroll from 'src/hooks/useFadedScroll';
import Footer from '../ActionFooter';
import CateringItem from './CateringItem';
import { Wrapper } from './styled';

export default function AddCatering(props: any) {
  const { closeDialog, cancelButtonLabel, activeLeg, flightId, meInList } =
    props;
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const ref = useRef(null);
  useFadeScroll({ scrollRef: ref, size: 'small' });

  const data = useSelector(state =>
    entitySelector(state, 'flight', flightId, 'flight')
  );
  const cateringOptions = get(
    data,
    ['flight_lines', activeLeg, 'cateringOptions'],
    []
  );
  const flightLineId = get(data, ['flight_lines', activeLeg, 'id'], []);
  const initValue = mapArrayByReference(
    cateringOptions.map(item => item.cateringId),
    meInList?.caterings?.map(item => item.catering_id)
  );

  const handleSubmit = async (value: any, formik: FormikHelpers<any>) => {
    dispatch({
      type: '@flight/selectCatering',
      payload: {
        data: value.value.filter(item => !!item),
        flightLineId,
        setSubmitting,
        closeDialog
      }
    });
  };

  return (
    <>
      <Wrapper sx={{ width: 600 }}>
        <Grid container>
          <Grid container item sx={{ px: 2 }}>
            <Grid item xs={7}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Catering choices
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Allergy
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Add
              </Typography>
            </Grid>
          </Grid>

          <Formik initialValues={{ value: initValue }} onSubmit={handleSubmit}>
            {props => (
              <Form
                style={{ height: '100%', width: '100%' }}
                className="flex-space-between-column"
              >
                <Box
                  ref={ref}
                  sx={{
                    mt: 2,
                    mb: 1.5,
                    width: '100%',
                    maxHeight: '400px',
                    overflow: 'auto',
                    borderRadius: '4px'
                  }}
                >
                  {cateringOptions.map(
                    (item: Record<string, any>, index: number) => {
                      return (
                        <CateringItem data={item} index={index} key={index} />
                      );
                    }
                  )}
                </Box>

                <Footer
                  cancelButtonLabel={cancelButtonLabel}
                  submitButtonLabel="Confirm"
                  closeDialog={closeDialog}
                  disabledSubmit={isSubmitting}
                  isSubmitting={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </Grid>
      </Wrapper>
    </>
  );
}

import Icon from '@common/components/Icon';
import { TextFieldForm } from '@common/formElement';
import Select2Form from '@common/formElement/Select2Form';
import { entitySelector } from '@core/selector/entitySelector';
import { AddLuggageSchema } from '@flight/helper/schema';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import { dropdownDataSelector } from '@user/selector/userInfoSelector';
import { Form, Formik, FormikHelpers } from 'formik';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../ActionFooter';
import LuggageItem from './LuggageItem';
import { Wrapper } from './styled';
import { useState } from 'react';

export default function EditLuggage(props: any) {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const { closeDialog, cancelButtonLabel, paxId } = props;
  const data = useSelector(state =>
    entitySelector(state, 'flight', paxId, 'passenger')
  );

  const handleSubmit = (value: any, formik: FormikHelpers<any>) => {
    dispatch({
      type: '@flight/addLuggagePax',
      payload: {
        data: {
          ...value,
          customerId: data.paxId,
          flightLines_Id: data.flightLineId
        },
        paxId,
        setSubmitting
      }
    });
  };
  const luggageList = get(data, 'customer.luggage', []);
  const options = useSelector(dropdownDataSelector).luggage_types ?? [];
  return (
    <>
      <Wrapper sx={{ width: 600 }}>
        {luggageList.length > 0 ? (
          <>
            <Box
              className="flex-center-rows"
              sx={{ flexWrap: 'wrap', gap: 3, mt: 1 }}
            >
              {luggageList.map((item, index) => {
                return <LuggageItem data={item} key={index} />;
              })}
            </Box>
            <Divider sx={{ color: 'neutral.light1', my: 3 }} />
          </>
        ) : null}
        <Formik
          initialValues={{
            type_of_luggage: '',
            weight: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={AddLuggageSchema}
          validateOnMount
        >
          {({ initialValues, isValid }) => (
            <Form>
              <Box className="flex-center-rows" sx={{ gap: 1, mt: 0 }}>
                <Grid container columnSpacing={1} rowSpacing={0}>
                  <Grid item xs={12} sm={6}>
                    <Select2Form
                      name="type_of_luggage"
                      config={{
                        noOptionText: 'Select luggage type',
                        placeholder: 'Select luggage type',
                        fullWidth: true,
                        options: options
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="weight"
                      config={{
                        label: 'Luggage weight (kg)',
                        required: true,
                        fullWidth: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                </Grid>
                <IconButton
                  color="info"
                  type="submit"
                  size="medium"
                  disabled={!isValid || luggageList.length >= 5 || submitting}
                >
                  <Icon
                    size="sm"
                    icon={'icon-Plus-filled'}
                    sx={{ color: 'primary.dark2' }}
                  />
                </IconButton>
              </Box>
            </Form>
          )}
        </Formik>

        <Footer
          cancelButtonLabel={cancelButtonLabel}
          closeDialog={closeDialog}
        />
      </Wrapper>
    </>
  );
}

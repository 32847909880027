import Icon from '@common/components/Icon';
import { Grid, IconButton, Skeleton } from '@mui/material';
import {
  AvatarWrapper,
  ClientItemWrapper,
  MenuWrapper,
  TableWrapper
} from './styled';
const PassengerItemSkeleton = () => {
  return (
    <ClientItemWrapper>
      <AvatarWrapper>
        <Skeleton
          variant="rectangular"
          width={64}
          height={64}
          className="avatar"
        />
      </AvatarWrapper>
      <TableWrapper>
        <Grid container>
          <Grid sx={{ pl: 1 }} item xs={4}>
            <Skeleton variant="text" width={40} />
          </Grid>
          <Grid item xs={2}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={1.5}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={1.5}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={1.5}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={1.5}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper>
        <IconButton
          sx={{
            mr: 3
          }}
        >
          <Icon
            icon="icon-dot-menu"
            sx={{ color: 'neutral.dark1' }}
            size="md"
          />
        </IconButton>
      </MenuWrapper>
    </ClientItemWrapper>
  );
};

export default PassengerItemSkeleton;

import { useState, useEffect } from 'react';

// Custom hook to get the window width
const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth); // Initialize with current window width

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update width on resize
    };

    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []); // Run effect only on mount/unmount

  return windowWidth; // Return the current window width
};

export default useWindowWidth;

import { styled } from '@mui/material';

export const Wrapper = styled('div', {
  name: 'PreviewCrewMedicalDialogWrapper'
})(({ theme }) => ({
  padding: '24px 32px'
}));

export const ImagePreview = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  borderRadius: theme.spacing(1)
}));

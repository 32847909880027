import { styled } from '@mui/material';

export const EmptyBox = styled('div', {
  name: 'EmptyBox'
})(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.neutral.light3,
  marginTop: theme.spacing(4)
}));

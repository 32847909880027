import { styled } from '@mui/material';

export const FlightLineInfo = styled('div', {
  name: 'FlightLineInfo'
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '4px',
  justifyContent: 'center',
  width: '400px',
  height: '141px',
  margin: '0px -32px',
  padding: theme.spacing(4),
  backgroundImage: 'url(/images/flight_line_bg.png)'
}));

export const StartPoint = styled('div', {
  name: 'StartPoint'
})(({ theme }) => ({
  position: 'absolute',
  top: '40px',
  left: '2px',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.positive.light3}`,
  backgroundColor: theme.palette.positive.dark1
}));

export const EndPoint = styled('div', {
  name: 'EndPoint'
})(({ theme }) => ({
  position: 'absolute',
  top: '40px',
  right: '2px'
}));

export const CurvedLine = styled('div', {
  name: 'EndPoint'
})(({ theme }) => ({
  position: 'absolute',
  top: '16px',
  left: 0,
  right: 0,
  margin: 'auto',
  textAlign: 'center',
  img: {
    position: 'absolute',
    width: '20px',
    height: '20px',
    top: -8,
    left: 0,
    right: 0,
    margin: 'auto'
  }
}));
export const CurvedLineSVG = () => (
  <svg
    width="130"
    height="23"
    viewBox="0 0 130 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 22C1 22 30.3573 1.12838 64.4832 1.0006C99.0018 0.87084 129 22 129 22"
      stroke="#888F97"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="7 7"
    />
  </svg>
);

export const EndPointIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7721_54775)">
      <mask
        id="path-1-outside-1_7721_54775"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="20"
        height="25"
        fill="black"
      >
        <rect fill="white" x="2" width="20" height="25" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6569 4.42641C20.781 7.66162 20.781 12.9069 17.6569 16.1421L12 22L6.34315 16.1421C3.21895 12.9069 3.21895 7.66162 6.34315 4.42641C9.46734 1.1912 14.5327 1.1912 17.6569 4.42641ZM12.0001 12.6512C13.2624 12.6512 14.2858 11.5915 14.2858 10.2843C14.2858 8.97705 13.2624 7.91734 12.0001 7.91734C10.7377 7.91734 9.71436 8.97705 9.71436 10.2843C9.71436 11.5915 10.7377 12.6512 12.0001 12.6512Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6569 4.42641C20.781 7.66162 20.781 12.9069 17.6569 16.1421L12 22L6.34315 16.1421C3.21895 12.9069 3.21895 7.66162 6.34315 4.42641C9.46734 1.1912 14.5327 1.1912 17.6569 4.42641ZM12.0001 12.6512C13.2624 12.6512 14.2858 11.5915 14.2858 10.2843C14.2858 8.97705 13.2624 7.91734 12.0001 7.91734C10.7377 7.91734 9.71436 8.97705 9.71436 10.2843C9.71436 11.5915 10.7377 12.6512 12.0001 12.6512Z"
        fill="#E63946"
      />
      <path
        d="M17.6569 16.1421L19.0955 17.5315L19.0955 17.5314L17.6569 16.1421ZM12 22L10.5613 23.3893L12 24.8791L13.4387 23.3893L12 22ZM6.34315 16.1421L7.78183 14.7528L7.78183 14.7528L6.34315 16.1421ZM6.34315 4.42641L7.78183 5.81572L7.78183 5.81572L6.34315 4.42641ZM19.0955 17.5314C22.9682 13.5212 22.9682 7.04732 19.0955 3.03709L16.2182 5.81572C18.5939 8.27591 18.5939 12.2926 16.2182 14.7528L19.0955 17.5314ZM13.4387 23.3893L19.0955 17.5315L16.2182 14.7528L10.5613 20.6107L13.4387 23.3893ZM4.90446 17.5315L10.5613 23.3893L13.4387 20.6107L7.78183 14.7528L4.90446 17.5315ZM4.90446 3.03709C1.03185 7.04732 1.03185 13.5212 4.90446 17.5315L7.78183 14.7528C5.40606 12.2926 5.40606 8.27591 7.78183 5.81572L4.90446 3.03709ZM19.0955 3.03709C15.185 -1.01236 8.81497 -1.01236 4.90446 3.03709L7.78183 5.81572C10.1197 3.39476 13.8803 3.39476 16.2182 5.81572L19.0955 3.03709ZM12.2858 10.2843C12.2858 10.5533 12.0926 10.6512 12.0001 10.6512V14.6512C14.4323 14.6512 16.2858 12.6297 16.2858 10.2843H12.2858ZM12.0001 9.91734C12.0926 9.91734 12.2858 10.0152 12.2858 10.2843H16.2858C16.2858 7.93888 14.4323 5.91734 12.0001 5.91734V9.91734ZM11.7144 10.2843C11.7144 10.0152 11.9075 9.91734 12.0001 9.91734V5.91734C9.56789 5.91734 7.71436 7.93888 7.71436 10.2843H11.7144ZM12.0001 10.6512C11.9075 10.6512 11.7144 10.5533 11.7144 10.2843H7.71436C7.71436 12.6297 9.56789 14.6512 12.0001 14.6512V10.6512Z"
        fill="#FDEDEE"
        mask="url(#path-1-outside-1_7721_54775)"
      />
    </g>
    <defs>
      <clipPath id="clip0_7721_54775">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import Select from '@common/components/Select';
import { FormControl, FormLabel, MenuItem } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const Select2Form = ({ config, name, formik }: any) => {
  const [field, meta] = useField(name ?? 'TextField');
  const [focus, setFocus] = React.useState<boolean>(false);
  const {
    label,
    disabled,
    sx,
    placeholder,
    fullWidth = true,
    size = 'small',
    required,
    autoFocus,
    maxLength,
    toUpperCase,
    onClick,
    options,
    noOptionText
  } = config;

  // fix: A component is changing an uncontrolled input
  if (!field.value) {
    field.value = config.defaultValue ?? '';
  }
  if (toUpperCase) {
    field.value = field.value.toUpperCase();
  }
  const haveError: boolean = meta.touched && Boolean(meta.error);
  const onBlur = (e: any) => {
    field.onBlur(e);
    setFocus(false);
  };
  const onClickFocus = (e: any) => {
    onClick && onClick();
    setFocus(true);
  };
  const props = {
    ...field,
    onBlur,
    onClick: onClickFocus,
    disabled: disabled,
    required,
    autoFocus,
    size,
    inputProps: {
      maxLength
    },
    placeholder,
    error: haveError && !focus,
    fullWidth,
    sx
  };
  return (
    <FormControl fullWidth>
      {label ? (
        <FormLabel
          sx={{ mb: 0.5, fontSize: 14, color: 'primary.dark3' }}
          required={required}
        >
          {label}
        </FormLabel>
      ) : null}

      <Select
        {...props}
        labelId="select-label"
        MenuProps={{ PaperProps: { sx: { maxHeight: 264 } } }}
        displayEmpty
        renderValue={(selected: any) => {
          return (
            <>
              {selected
                ? options.find(item => item.value === selected).label
                : noOptionText}
            </>
          );
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {options?.map((item, index) => {
          return (
            <MenuItem
              sx={{ whiteSpace: 'normal' }}
              key={index}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      {!focus && meta.error && meta.touched ? (
        <div className="errorMessage">{meta.error}</div>
      ) : null}
    </FormControl>
  );
};

export default Select2Form;

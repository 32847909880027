import React, { useRef, useState, useEffect } from 'react';
import { Typography, Tooltip, TypographyProps } from '@mui/material';
import useWindowWidth from 'src/hooks/useWindowWidth';

// Define the custom component's props, extending Material UI Typography props
interface TruncatedTextProps extends TypographyProps {
  children: React.ReactNode;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({
  children,
  ...typographyProps
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const width = useWindowWidth();
  // Check if the text is truncated
  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [children, width]);

  return (
    <Tooltip
      title={isTruncated ? children : ''}
      arrow
      disableHoverListener={!isTruncated}
    >
      <Typography
        ref={textRef}
        noWrap
        {...typographyProps}
        sx={{ width: '100%', display: 'block' }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedText;

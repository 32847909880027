import { styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'ConfirmDialogWrapper' })(
  ({ theme }) => ({
    overflow: 'hidden',
    padding: '32px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '600px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px'
    }
  })
);

export const CloseButton = styled('div', { name: 'CloseButton' })(
  ({ theme }) => ({
    position: 'absolute',
    top: '24px',
    right: '32px'
  })
);

import { IconButton } from '@mui/material';
import { CloseButton, Wrapper } from './styled';
import Icon from '@common/components/Icon';

export default function ComingSoon(props: any) {
  const { closeDialog } = props;
  return (
    <Wrapper>
      <CloseButton>
        <IconButton sx={{ p: 1, mr: -1 }} onClick={closeDialog}>
          <Icon size="sm" icon="icon-close" color="black" />
        </IconButton>
      </CloseButton>

      <img src="/images/comingsoon.png" alt="" />
    </Wrapper>
  );
}

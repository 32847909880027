import TruncatedText from '@common/components/TruncatedText';
import { Grid } from '@mui/material';
import { CateringOptionsWrapper, CateringWrapper } from './styled';
import { CheckboxForm } from '@common/formElement';

export default function CateringItem({
  data,
  index
}: {
  data: Record<string, any>;
  index: number;
}) {
  const allergies: string[] = Object.values(data.allergies);
  return (
    <CateringOptionsWrapper
      sx={{
        backgroundColor: index % 2 === 0 ? 'primary.light2' : 'primary.light3'
      }}
    >
      <Grid container item>
        <Grid item xs={7}>
          <TruncatedText variant="subtitle3" color="primary.dark3">
            {data?.catering?.catering_name}
          </TruncatedText>
        </Grid>
        <Grid item xs={4}>
          <CateringWrapper>
            {allergies.length ? (
              allergies.map((item: string, index: number) => (
                <li key={index}>
                  <TruncatedText color="neutral.dark3" variant="body2" noWrap>
                    {item}
                  </TruncatedText>
                </li>
              ))
            ) : (
              <li key={index} style={{ color: '#888F97' }}>
                <TruncatedText variant="body2" noWrap>
                  None
                </TruncatedText>
              </li>
            )}
          </CateringWrapper>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{ height: '100%', alignItems: 'center !important' }}
        >
          <CheckboxForm
            name={`value[${index}]`}
            config={{
              size: 'small',
              required: true,
              checkedValue: data.cateringId
            }}
          />
        </Grid>
      </Grid>
    </CateringOptionsWrapper>
  );
}

import { Box, IconButton } from '@mui/material';
import Footer from '../ActionFooter';
import { ImagePreview, Wrapper } from './styled';
import Icon from '@common/components/Icon';
import { useMemo, useState } from 'react';

export default function PreviewCrewMedical(props: any) {
  const { closeDialog, cancelButtonLabel, initialIndex, images } = props;
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const disabledPrevious = useMemo(() => currentIndex === 0, [currentIndex]);

  const disabledNext = useMemo(
    () => currentIndex === images.length - 1,
    [currentIndex, images]
  );

  const arrows = useMemo(
    () => [
      {
        iconName: 'icon-Chevron-left-filled',
        action: () => setCurrentIndex(currentIndex => currentIndex - 1),
        disabled: disabledPrevious
      },
      {
        iconName: 'icon-Chevron-right-filled',
        action: () => setCurrentIndex(currentIndex => currentIndex + 1),
        disabled: disabledNext
      }
    ],
    [disabledPrevious, disabledNext]
  );

  return (
    <>
      <Box
        sx={{
          width: '536px',
          maxHeight: '357px',
          overflowY: 'scroll',
          padding: '24px 32px 0'
        }}
      >
        <ImagePreview
          src={images[currentIndex].url}
          alt={images[currentIndex].url}
        />
      </Box>
      <Wrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          {arrows.map((arrow, index) => (
            <IconButton
              key={index}
              sx={theme => ({
                background: theme.palette.primary.dark3,
                borderRadius: '4px',
                opacity: 0.9,
                padding: '4px',
                color: theme.palette.neutral.white,
                '&:hover': { background: theme.palette.primary.dark3 },
                '&:disabled': {
                  background: theme.palette.neutral.main,
                  color: theme.palette.neutral.dark2
                }
              })}
              disabled={arrow.disabled}
              onClick={arrow.action}
            >
              <Icon haveBackground icon={arrow.iconName} size="md" />
            </IconButton>
          ))}
        </Box>
        <Box sx={{ mt: 1 }}>
          <Footer
            cancelButtonLabel={cancelButtonLabel}
            closeDialog={closeDialog}
          />
        </Box>
      </Wrapper>
    </>
  );
}

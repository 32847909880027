import { isEmpty } from 'lodash';
import Icon from '@common/components/Icon';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import { type MouseEvent, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AvatarWrapper,
  CrewItemWrapper,
  CrewStatusWrapper,
  MenuWrapper,
  TableWrapper
} from './styled';
import { useNavigate } from 'react-router';

const placeholder = (
  <Typography variant="body2" color="neutral.main">
    <i>waiting...</i>
  </Typography>
);

export default function CrewTableItem({ item, loadData, columnNameWidth }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const anchorPopover = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const crewStatus = useMemo(() => {
    switch (item.status) {
      case 1:
        return {
          title: 'Awaiting crew acceptance',
          color: 'warning.dark1',
          name: 'icon-passport-pending-bold',
          background: '#faefc3'
        };
      case 3:
        return {
          title: 'Identify verified',
          color: 'positive.dark1',
          background: '#F3FCF4',
          name: 'icon-passport-bold'
        };
      case 4:
        return {
          title: 'Identify rejected',
          color: 'danger.main',
          background: '#F6BCB8',
          name: 'icon-passport-fail-bold'
        };
      default:
        return {
          title: 'Identify verifying',
          color: 'primary.dark1',
          background: '#C7E9F9',
          name: 'icon-passport-pending-bold'
        };
    }
  }, [item]);

  if (isEmpty(item)) return null;

  const { firstName, lastName, deleting, email } = item;

  const crewName = `${firstName ?? ''} ${lastName ?? ''}`.trim();

  const onDeleteCrew = (event) => {
    event.stopPropagation();
    handleClose();
    dispatch({
      type: '@crew/deleteCrew',
      payload: { id: item.id, crewName: crewName || email, cb: onDeleteSuccess }
    });
  };

  const onDeleteSuccess = () => {
    handleClose();
    loadData();
  };

  const onViewCrewDetail = (event) => {
    event.stopPropagation();
    handleClose();
    navigate(`/crew/${item.id}`);
  };

  return (
    <CrewItemWrapper deleting={deleting}>
      <AvatarWrapper onClick={onViewCrewDetail}>
        <Avatar
          className="fs-exclude"
          variant="square"
          sx={{
            width: 64,
            height: 64,
            bgcolor: 'neutral.light2'
          }}
          src={item?.onfido_image}
        />
      </AvatarWrapper>
      <TableWrapper>
        <Grid container>
          <Grid sx={{ pl: 2.5 }} item xs={1} xl={(120 / 1104) * 12} onClick={onViewCrewDetail}>
            <Typography color="neutral.dark1" variant="body2" noWrap>
              {item.id}
            </Typography>
          </Grid>
          <Grid item xs={3} xl={(479 / 1104) * 12} onClick={onViewCrewDetail}>
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Typography
                color="neutral.black"
                variant="subtitle3"
                noWrap
              >
                {crewName || placeholder}
              </Typography>
              <Typography
                color="neutral.black"
                variant="inherit"
                noWrap
                sx={{
                  fontSize: 12,
                }}
              >
                {email}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} xl={(180 / 1104) * 12} onClick={onViewCrewDetail}>
            <Typography color="neutral.dark3" variant="body2" noWrap>
              {item?.date_of_birth ?? placeholder}
            </Typography>
          </Grid>
          <Grid item xs={3} xl={(180 / 1104) * 12} onClick={onViewCrewDetail}>
            <Typography color="neutral.dark3" variant="body2" noWrap>
              {item.crew_type?.crew_type_name ?? placeholder}
            </Typography>
          </Grid>
          <Grid item xs={1} xl={(96 / 1104) * 12} onClick={onViewCrewDetail}>
            <Tooltip title={crewStatus.title} arrow>
              <CrewStatusWrapper sx={{ background: crewStatus.background }}>
                <Icon
                  sx={{ color: crewStatus.color }}
                  icon={crewStatus.name}
                  size="lg"
                />
              </CrewStatusWrapper>
            </Tooltip>
          </Grid>
          <Grid item xs={1} xl={(48 / 1104) * 12}>
            <MenuWrapper ref={anchorPopover} onClick={handleClick}>
              <IconButton
                size="medium"
                sx={{
                  ':hover': {
                    '.icon': {
                      color: 'primary.main',
                      transition: 'all 0.2s ease-in-out'
                    }
                  }
                }}
              >
                <Icon
                  icon="icon-dot-menu"
                  sx={{ color: 'neutral.dark1' }}
                  size="sm"
                />
              </IconButton>
            </MenuWrapper>
            <Menu
              open={Boolean(open)}
              anchorEl={anchorPopover.current}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              sx={{ mt: -1.5 }}
            >
              <MenuItem onClick={onViewCrewDetail} disableRipple>
                <Typography color="neutral.dark3" variant="body2">
                  View details
                </Typography>
              </MenuItem>
              <MenuItem onClick={onDeleteCrew} disableRipple>
                <Typography color="danger.main" variant="body2">
                  Remove
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </TableWrapper>
    </CrewItemWrapper>
  );
}

import Icon from '@common/components/Icon';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { Box, Grid, Typography } from '@mui/material';

const HistorySection = () => {
  return (
    <SectionWrapper>
      <Box className="flex-center-rows" sx={{ gap: 1, mb: 4 }}>
        <Icon icon="icon-history" size="lg" sx={{ color: 'primary.dark1' }} />
        <Typography variant="head3Mons" color="primary.dark2">
          Most recent edits
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default HistorySection;

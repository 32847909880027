import Breadcrumbs from '@common/components/Breadcrumbs';
import { Header } from './styled';

export default function CrewDetailHeader() {
  return (
    <Header>
      <Breadcrumbs
        data={[{ to: '/crews', label: 'Crew' }, { label: 'Crew detail' }]}
      />
    </Header>
  );
}

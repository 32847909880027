import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { get, size, unset } from 'lodash';

const initialState= {
  crew: {},
  paging: {
    data: [],
    last_page: 1,
    current_page: 1,
    per_page: 10,
    total: 0,
    refreshing: false,
    loading: false
  }
}

export const crewReducer = createSlice({
  name: 'crew',
  initialState,
  reducers: {
    INIT: (state, action) => {
      state.paging = {
        ...state.paging,
        data: [],
        loading: true
      };
    },
    FULLFIL: (state, action) => {
      const {
        data: crews,
        meta: { last_page, total, current_page, per_page }
      } = action.payload;

      state.paging = {
        data: crews,
        last_page,
        current_page,
        per_page,
        total,
        refreshing: false,
        loading: false
      };
    },
    fetchDetail: (state, action) => {
      const { data: crews } = action.payload;
      const newState = {
        ...get(state, 'crew', {}),
        [crews.id]: crews
      };
      state.crew = newState;
    },
    deleteEntity: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const newState = produce(state.crew, draft => {
        unset(draft, id);
      });
      state.crew = newState;
    },
    updateEntity: (state, action: PayloadAction<Record<string, any>>) => {
      const { id, data } = action.payload;
      const newState = produce(state.crew, draft => {
        draft[id] = { ...draft[id], ...data };
      });
      state.crew = newState;
    },
    refreshPaging: (state, action: PayloadAction<Record<string, any>>) => {
      if (state.paging.current_page === 1) {
        if (size(state.paging.data) < 20) {
          state.paging.data.unshift(get(action, 'data'));
        } else {
          state.paging.data.slice(-1);
          state.paging.data.unshift(get(action, 'data'));
        }
      }
      state.paging.total = state.paging.total + 1;
      state.paging.last_page = Math.ceil(
        state.paging.total / state.paging.per_page
      );
    },
    reset: () => initialState
  }
});

import { CircularProgress, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';

const DEFAULT_THRESHOLD = 500;

export default function FakeLoadingProgressBar({
  done,
  type = 'linear',
  threshold = DEFAULT_THRESHOLD
}: {
  done: boolean;
  type?: 'linear' | 'circular';
  threshold?: number;
}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (done) {
        setProgress(100);
        return;
      }
      setProgress(oldProgress => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, threshold);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);
  if (type === 'circular')
    return (
      <CircularProgress
        variant="determinate"
        value={progress}
        color="primary"
      />
    );
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{ height: 8, borderRadius: 0 }}
      color="primary"
    />
  );
}

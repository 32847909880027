import Icon from '@common/components/Icon';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { ElementType, useCallback, useRef, useState } from 'react';
import { DialogHeader } from './BaseDialog.styled';

export type propsDialogComponent = {
  component?: ElementType;
  props?: Record<string, any>;
  closable?: boolean;
  onClose?: () => void;
  id?: number;
  closeDialog?: (props?: any) => void;
  width?: number;
  theme?: 'black' | 'white';
  title?: string;
  closeable?: boolean;
  closeIcon?: boolean;
  onSubmit?: () => void;
  type?: 'normal' | 'failure';
  padding?: Record<string, any>;
  inMobile?: 'full' | 'bottom';
  resolve: any;
};

export const BaseDialog = React.forwardRef(
  (propsValues: propsDialogComponent, ref) => {
    const {
      component: RenderInner,
      props,
      closeDialog = () => {},
      inMobile,
      resolve
    } = propsValues;
    const { closeable = true } = props;
    const [forceOpen, setForceOpen] = useState(false);
    const valueRef = useRef(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const setValue = useCallback((value: boolean) => {
      valueRef.current = value;
    }, []);
    const onClose = () => {
      if (!closeable || forceOpen) return;
      resolve(valueRef.current);
      closeDialog();
    };
    return (
      <Dialog
        open={true}
        onClose={onClose}
        fullScreen={fullScreen && inMobile === 'full'}
      >
        {props?.title ? (
          <DialogTitle>
            <DialogHeader>
              <Typography variant="h6" color="neutral.dark3">
                {props.title}
              </Typography>
              <IconButton sx={{ p: 1, mr: -1 }} onClick={onClose}>
                <Icon size="sm" icon="icon-close" color="black" />
              </IconButton>
            </DialogHeader>
          </DialogTitle>
        ) : null}
        <DialogContent>
          {RenderInner && (
            <RenderInner
              inDialog={true}
              closeDialog={onClose}
              setValue={(value: boolean) => setValue(value)}
              setForceOpen={setForceOpen}
              value={valueRef.current}
              {...props}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
);

import React, { createContext, useContext } from 'react';

// Create the context
const FlightDetailContext = createContext(null);

// Custom hook to use the FlightDetailContext
export const useFlightDetail = () => useContext(FlightDetailContext);

// Context Provider component
export const FlightDetailProvider = ({
  children,
  flightData,
  legData,
  activeLeg,
  setActiveLeg,
  flight_lines
}) => {
  return (
    <FlightDetailContext.Provider
      value={{ flightData, legData, activeLeg, setActiveLeg, flight_lines }}
    >
      {children}
    </FlightDetailContext.Provider>
  );
};

import { listEntitySelector } from '@core/selector/entitySelector';
import { flatStringParams } from '@helper/stringFunction';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScrollEnd from './useScrollEnd';

export default function useEntityFullfil(
  module: string,
  apiUrl: string,
  params?: Record<string, any>,
  canLoadMore?: boolean,
  resource?: string
) {
  const dispatch = useDispatch();
  const paging = apiUrl.concat(flatStringParams(params));
  const data = useSelector(state =>
    listEntitySelector(state, paging, module, resource)
  );
  const pagingRef = useRef(paging);
  const paramsRef = useRef(params);

  // Update ref whenever paging changes
  useEffect(() => {
    pagingRef.current = paging;
  }, [paging]);

  useEffect(() => {
    paramsRef.current = params;
  }, [params]);

  const loadData = useCallback(() => {
    dispatch({
      type: '@entity/FULLFIL',
      payload: {
        params: paramsRef.current,
        paging: pagingRef.current,
        resource,
        module,
        apiUrl
      },
      meta: {}
    });
  }, [dispatch, module, apiUrl, resource]);

  useScrollEnd(canLoadMore ? loadData : undefined);

  useEffect(() => {
    if (data.refreshing) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.refreshing]);

  useEffect(() => {
    !data?.next_cursor && !data.refreshing && loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  return [data, data.loading, loadData];
}

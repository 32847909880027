import { PaginationBar } from '@common/components/Pagination';
import { CrewTableBlock, CrewTableHeader } from '@crew/components/crew-list';
import { Box } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useEntityFullfil from 'src/hooks/useEntityFullfil';
import { useDispatch, useSelector } from 'react-redux';
import { get, size } from 'lodash';
import useWindowSize from './use-window-size';

const CrewList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const container = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const page = parseInt(searchParams.get('page')) || 1;
  const params = useMemo(() => {
    return { ...Object.fromEntries(searchParams.entries()), limit: 20 };
  }, [searchParams]);
  const data = useSelector<any, any>(state => {
    return get(state, 'crew.paging');
  });
  const [, , loadData] = useEntityFullfil('crew', '/verify/crews', params);
  const [width] = useWindowSize();
  const [columnNameWidth, setColumnNameWidth] = useState(0);

  useEffect(() => {
    return () => {
      dispatch({ type: 'crew/reset' });
    };
  }, [dispatch]);

  useEffect(() => {
    if (container.current) {
      setColumnNameWidth(
        container.current.parentElement.querySelector('#crew-name').clientWidth
      );
    }
  }, [width]);

  const refreshList = () => {
    if (
      data.current_page > 1 &&
      data.current_page === data.last_page &&
      size(data.data) === 1
    ) {
      setSearchParams(
        prevParams => {
          return new URLSearchParams({
            ...Object.fromEntries(prevParams.entries()),
            page: `${data.current_page - 1}`
          });
        },
        { replace: true }
      );
    }
    loadData();
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1
        }}
      >
        <CrewTableHeader />
      </Box>
      <Box sx={{ pr: 8 }} ref={container}>
        <CrewTableBlock
          data={data}
          columnNameWidth={columnNameWidth}
          loadData={refreshList}
        >
          {data.last_page > 1 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '8px 0 24px'
              }}
            >
              <PaginationBar
                count={data.last_page}
                page={page}
                color="primary"
              />
            </Box>
          )}
        </CrewTableBlock>
      </Box>
    </Box>
  );
};

export default CrewList;

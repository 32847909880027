import { styled } from '@mui/material';

export const Wrapper = styled('div', {
  name: 'Wrapper'
})(({ theme }) => ({}));

export const GeneralInfo = styled('div', {
  name: 'GeneralInfo'
})(({ theme }) => ({
  display: 'flex',
  margin: `0 ${theme.spacing(8)}`,
  borderRadius: '16px',
  backgroundColor: 'white',
  height: '542px',
  overflow: 'hidden',
  position: 'relative',
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7C2CDFF' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
  }
}));

export const DetailInfo = styled('div', {
  name: 'DetailInfo'
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: `${theme.spacing(4)} ${theme.spacing(8)}`,
  gap: theme.spacing(6),
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  borderRadius: '16px',
  backgroundColor: 'white',
  overflow: 'hidden'
}));

export const SectionWrapper = styled('div', {
  name: 'SectionWrapper'
})(({ theme }) => ({
  paddingBottom: theme.spacing(6),
  position: 'relative',
  '::before': {
    content: '""',
    position: 'absolute',
    height: '1px',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B7C2CDFF' stroke-width='4' stroke-dasharray='3%2c12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
  },
  '&:last-child': {
    paddingBottom: 0
  },
  '&:last-child::before': {
    display: 'none'
  }
}));

import { Grid, Typography } from '@mui/material';
import { TableHeaderWrapper, TableWrapper } from './styled';

const CrewTableHeader = () => {
  return (
    <TableHeaderWrapper>
      <TableWrapper>
        <Grid container>
          <Grid item sx={{ pl: 2.5 }} xl={(120 / 1104) * 12} xs={1}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              ID
            </Typography>
          </Grid>
          <Grid item xs={3} xl={(479 / 1104) * 12} id="crew-name">
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Name
            </Typography>
          </Grid>
          <Grid item xs={3} xl={(180 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Date of birth
            </Typography>
          </Grid>
          <Grid item xs={3} xl={(180 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Crew type
            </Typography>
          </Grid>
          <Grid item xs={1} xl={(96 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Crew status
            </Typography>
          </Grid>
          <Grid item xs={1} xl={(48 / 1104) * 12}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap />
          </Grid>
        </Grid>
      </TableWrapper>
    </TableHeaderWrapper>
  );
};

export default CrewTableHeader;

import LoadingOverlay from '@common/components/LoadingOverlay';
import router from '@utils/route/router';
import ScrollProvider from '@utils/scroll/ScrollProvider';
import { useRef } from 'react';
import { RouterProvider } from 'react-router-dom';

function App() {
  const scrollRef = useRef(undefined);

  return (
    <ScrollProvider scrollRef={scrollRef}>
      <LoadingOverlay />
      <RouterProvider router={router} />
    </ScrollProvider>
  );
}

export default App;

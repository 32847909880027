import {
  CrewBanner,
  CrewBannerLeft,
  CrewBannerLeftEmail,
  CrewBannerLeftInfo,
  CrewBannerLeftName,
  CrewBannerDivider,
  CrewBannerRight
} from './styled';
import { Avatar, Tooltip } from '@mui/material';
import Icon from '@common/components/Icon';
import { useMemo } from 'react';
import { get, trim } from 'lodash';

export default function Banner({ data }) {
  const crewStatus = useMemo(() => {
    switch (data.status) {
      case 1:
        return {
          title: 'Awaiting crew acceptance',
          color: 'warning.dark1',
          name: 'icon-passport-pending-bold',
          background: '#faefc3'
        };
      case 3:
        return {
          title: 'Identify verified',
          color: 'positive.dark1',
          background: '#F3FCF4',
          name: 'icon-passport-pending-bold'
        };
      case 4:
        return {
          title: 'Identify rejected',
          color: 'danger.main',
          background: '#F6BCB8',
          name: 'icon-passport-fail-bold'
        };
      default:
        return {
          title: 'Identify verifying',
          color: 'primary.dark1',
          background: '#C7E9F9',
          name: 'icon-passport-bold'
        };
    }
  }, [data]);

  const placeholder = 'waiting...';

  const fullName = useMemo(
    () =>
      trim(`${get(data, 'firstName') || ''} ${get(data, 'lastName') || ''}`) ||
      placeholder,
    [data]
  );

  return (
    <CrewBanner>
      <CrewBannerLeft>
        <Avatar
          sx={theme => ({
            width: 80,
            height: 80,
            border: `4px solid ${theme.palette.positive.dark1}`,
            background: '#B9CFEF'
          })}
          src={data.onfido_image}
        />
        <CrewBannerLeftInfo>
          <Icon icon="icon-profile" sx={{ color: 'primary.dark2' }} />
          <Tooltip arrow title={fullName}>
            <CrewBannerLeftName>{fullName}</CrewBannerLeftName>
          </Tooltip>
        </CrewBannerLeftInfo>
        <CrewBannerDivider />
        <CrewBannerLeftInfo>
          <Icon icon="icon-mail" sx={{ color: 'primary.dark2' }} />
          <Tooltip arrow title={data.email || placeholder}>
            <CrewBannerLeftEmail>
              {data.email || placeholder}
            </CrewBannerLeftEmail>
          </Tooltip>
        </CrewBannerLeftInfo>
      </CrewBannerLeft>
      <CrewBannerRight
        sx={{
          background: crewStatus.background,
          borderColor: crewStatus.color,
          color: crewStatus.color
        }}
      >
        <Icon
          sx={{ color: crewStatus.color }}
          icon={crewStatus.name}
          size="lg"
        />
        {crewStatus.title}
      </CrewBannerRight>
    </CrewBanner>
  );
}

import { Grid, Typography } from '@mui/material';
import {
  AvatarWrapper,
  MenuWrapper,
  TableHeaderWrapper,
  TableWrapper
} from './styled';
const PassengerFlightHeader = () => {
  return (
    <TableHeaderWrapper>
      <AvatarWrapper></AvatarWrapper>
      <TableWrapper>
        <Grid container>
          <Grid item xs sx={{ pl: 1 }}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Passport status
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Luggage
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Catering
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              Food allergy
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="neutral.dark1" variant="subtitle2" noWrap>
              UKBF status
            </Typography>
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper sx={{ mr: 3 }}>
        <Typography color="neutral.dark1" variant="subtitle2" noWrap>
          Action
        </Typography>
      </MenuWrapper>
    </TableHeaderWrapper>
  );
};

export default PassengerFlightHeader;

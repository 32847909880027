import TruncatedText from '@common/components/TruncatedText';
import { getName } from '@helper/stringFunction';
import { Grid } from '@mui/material';
import { CateringOptionsWrapper, CateringWrapper } from './styled';

export default function CateringItem({
  customer,
  index
}: {
  customer: Record<string, any>;
  index: number;
}) {
  const cateringChoices = customer.caterings;
  return (
    <CateringOptionsWrapper
      sx={{
        backgroundColor: index % 2 === 0 ? 'primary.light2' : 'primary.light3'
      }}
    >
      <Grid container item>
        <Grid item xs={6}>
          <TruncatedText variant="subtitle3" color="primary.dark3">
            {getName(customer)}
          </TruncatedText>
        </Grid>
        <Grid item xs={6}>
          <CateringWrapper>
            {cateringChoices.map((item: Record<string, any>, index: number) => (
              <li key={index}>
                <TruncatedText color="neutral.dark3" variant="body2" noWrap>
                  {item.catering_name}
                </TruncatedText>
              </li>
            ))}
          </CateringWrapper>
        </Grid>
      </Grid>
    </CateringOptionsWrapper>
  );
}

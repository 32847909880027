import Icon from '@common/components/Icon';
import Select from '@common/components/Select';
import { getConfig } from '@config/userLevelConfig/getConfig';
import { MenuItem, TextField } from '@mui/material';
import { userInfoSelector } from '@user/selector/userInfoSelector';
import { debounce, get, size } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  Counter,
  DropdownItemWrapper,
  DropdownWrapper,
  Header,
  MainButton,
  SearchBar,
  Title,
  ToolBarWrapper,
  Wrapper
} from './styled';
import { actionButtonList } from './actionButton';

type ActionButtonConfigProps =
  | {
      label?: string;
      action?: string;
      icon?: string;
      color?:
        | 'black'
        | 'white'
        | 'primary'
        | 'warning'
        | 'accent'
        | 'danger'
        | 'positive'
        | 'lightAccent'
        | undefined
        | string;
      component?: string;
      level?: number[];
    }
  | undefined;
export default function TopBar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const config = getConfig(userInfo?.customer_type_id, location.pathname);
  const crewPagination = useSelector<any, any>(state => {
    return get(state, 'crew.paging');
  });
  const [searchParams, setSearchParams] = useSearchParams({});
  const [initialFilter] = useState(searchParams.get('status'));

  const haveSearchBar = config?.headerConfig?.haveSearchBar ?? false;
  const actionButtonConfig: ActionButtonConfigProps =
    config?.headerConfig?.actionButton;

  const filterLists = get(config, 'headerConfig.filterItem', []);

  const hasShowCounter = useMemo(
    () => ['/crews'].includes(location.pathname),
    [location.pathname]
  );

  const debounceSearchBar = debounce(value => {
    if (!value) {
      searchParams.delete('q');
      setSearchParams(searchParams);
      return;
    }
    setSearchParams(prevParams => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        page: '1',
        q: value
      });
    });
  }, 500);
  const onSearchChange = (e: any) => {
    debounceSearchBar(e.target.value);
    e.preventDefault();
  };

  const onHandleFilterChange = (type: string) => (e: any) => {
    if (!e.target.value || e.target.value === 'all') {
      searchParams.delete(type);
      setSearchParams(searchParams);
      return;
    }
    setSearchParams(prevParams => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        [type]: e.target.value,
        page: '1',
      });
    });
    e.preventDefault();
  };

  const onButtonClick = () => {
    dispatch({
      type: actionButtonConfig?.action || ''
    });
  };
  const showActionButton =
    actionButtonConfig &&
    (!actionButtonConfig.level ||
      actionButtonConfig.level?.includes(userInfo?.customer_type_id));

  return (
    <Wrapper>
      <Header sx={{ mb: { xs: 2, md: 3 } }}>
        <Title sx={{ display: 'flex', flexDirection: 'column' }}>
          {config?.name}
          {hasShowCounter && (
            <Counter>
              Showing {size(get(crewPagination, 'data'))} of{' '}
              {get(crewPagination, 'total')}
            </Counter>
          )}
        </Title>
        {showActionButton ? (
          actionButtonConfig.component ? (
            actionButtonList[actionButtonConfig.component]
          ) : (
            <MainButton
              onClick={onButtonClick}
              size="large"
              color="secondary"
              variant="contained"
              startIcon={
                <Icon
                  icon={actionButtonConfig?.icon || ''}
                  color={actionButtonConfig?.color ?? 'white'}
                />
              }
            >
              <span className="buttonText">{actionButtonConfig?.label}</span>
            </MainButton>
          )
        ) : null}
      </Header>
      <ToolBarWrapper key={location.pathname}>
        {haveSearchBar ? (
          <SearchBar>
            <TextField
              size="small"
              defaultValue={searchParams.get('q') ?? null}
              fullWidth
              type="search"
              variant="filled"
              hiddenLabel
              placeholder={config?.headerConfig?.searchBarPlaceHolder ?? ''}
              onChange={onSearchChange}
              InputProps={{
                className: 'searchBar'
              }}
            />
          </SearchBar>
        ) : null}
        {filterLists.map((filterList, index) => (
          <DropdownWrapper key={index}>
            <Select
              size="small"
              onChange={onHandleFilterChange(filterList.type)}
              defaultValue={initialFilter ? parseInt(initialFilter) : 'all'}
              fullWidth
              renderValue={value => {
                const displayedValue = filterList.options.filter(
                  item => item.filterItemPathSearch === value
                )[0].filterItemName;
                return `${filterList.label} ${displayedValue}`;
              }}
              MenuProps={{
                sx: {
                  '.MuiPaper-root': {
                    marginTop: '8px'
                  }
                }
              }}
            >
              {filterList.options.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.filterItemPathSearch}>
                    <DropdownItemWrapper>
                      {item.icon ? <Icon icon={item.icon} size="sm" /> : null}
                      <div>{item.filterItemName}</div>
                    </DropdownItemWrapper>
                  </MenuItem>
                );
              })}
            </Select>
          </DropdownWrapper>
        ))}
      </ToolBarWrapper>
    </Wrapper>
  );
}

import { userLevelSelector } from '@user/selector/userInfoSelector';
import { useSelector } from 'react-redux';

// Wrapper component kiểm tra quyền
const AuthorizedComponent = ({ requiredRoles, children }) => {
  const userLevel = useSelector(userLevelSelector);
  const hasPermission = requiredRoles.includes(userLevel);
  if (!hasPermission) return null;
  return <>{children}</>;
};

export default AuthorizedComponent;

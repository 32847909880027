import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    head2Mons: React.CSSProperties;
    head3Mons: React.CSSProperties;
    head4Mons: React.CSSProperties;
    head5Mons: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    head2Mons?: React.CSSProperties;
    head3Mons?: React.CSSProperties;
    head4Mons?: React.CSSProperties;
    head5Mons?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    head2Mons: true;
    head3Mons: true;
    head4Mons: true;
    head5Mons: true;
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {}
  interface PaletteOptions {
    neutral: Record<string, any>;
    accent: Record<string, any>;
    danger: Record<string, any>;
    positive: Record<string, any>;
  }

  interface Palette {
    neutral: Record<string, any>;
    accent: Record<string, any>;
    danger: Record<string, any>;
    positive: Record<string, any>;
  }

  interface PaletteColorOptions {
    dark3: string;
    dark2: string;
    dark1: string;
    main: string;
    light1: string;
    light2: string;
    light3: string;
  }
  interface PaletteColor {
    dark3: string;
    dark2: string;
    dark1: string;
    main: string;
    light1: string;
    light2: string;
    light3: string;
  }
}

// A custom theme for this app
export const theme1 = createTheme({
  mixins: {},
  typography: {
    fontFamily: `'Open Sans', 'Montserrat', sans-serif`,
    htmlFontSize: 16, // base unit to calculate "rem"
    fontSize: 16, // default fontSize
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 300,
      fontSize: '96px',
      lineHeight: 1.167,
      letterSpacing: '-1.5px'
    },
    h2: {
      fontWeight: 300,
      fontSize: '60px',
      lineHeight: 1.2,
      letterSpacing: '0'
    },
    h3: {
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: 1.167,
      letterSpacing: '0'
    },
    h4: {
      fontWeight: 400,
      fontSize: '34px',
      lineHeight: 1.235,
      letterSpacing: '0.25px'
    },
    h5: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: 1.334,
      letterSpacing: '0'
    },
    h6: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: 1.6,
      letterSpacing: '0.15px'
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 1.5
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.43,
      letterSpacing: '0.17px'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 1.75,
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: 1.57,
      letterSpacing: '0.1px'
    },
    subtitle3: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: 1.57,
      letterSpacing: '0.1px',
      fontFamily: `'Open Sans', 'Montserrat', sans-serif`
    },
    overline: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 2.66,
      letterSpacing: '1px'
    },
    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.66,
      letterSpacing: '0.4px'
    },
    head2Mons: {
      fontFamily: 'Montserrat',
      fontSize: '32px',
      fontWeight: '600',
      lineHeight: '40px'
    },
    head3Mons: {
      fontFamily: 'Montserrat',
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '30.5px'
    },
    head4Mons: {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '22.5px'
    },
    head5Mons: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '14.63px'
    }
  },
  palette: {
    neutral: {
      dark3: '#29292B',
      dark2: '#585C61',
      dark1: '#888F97',
      white: '#ffffff',
      black: '#000000',
      main: '#B7C2CD',
      light1: '#CCD4DC',
      light2: '#E0E5EB',
      light3: '#F5F7FA'
    },
    primary: {
      dark3: '#00223D',
      dark2: '#0E5078',
      dark1: '#1D7FB3',
      main: '#48B1E5',
      light1: '#94D1F0',
      light2: '#C7E9F9',
      light3: '#EEF7FC'
    },
    accent: {
      dark3: '#36021C',
      dark2: '#71142A',
      dark1: '#AB2738',
      main: '#E63946',
      light1: '#EE757E',
      light2: '#F5B0B6',
      light3: '#FFE5EF'
    },
    danger: {
      dark3: '#380609',
      dark2: '#742725',
      dark1: '#B04742',
      main: '#EC685E',
      light1: '#F08D81',
      light2: '#F6BCB8',
      light3: '#FFE5EF'
    },
    warning: {
      dark3: '#302705',
      dark2: '#706229',
      dark1: '#B19E4C',
      main: '#F1D970',
      light1: '#F6E5A2',
      light2: '#F9EFC3',
      light3: '#FDFAEC'
    },
    positive: {
      dark3: '#052E11',
      dark2: '#286F3D',
      dark1: '#4BB069',
      main: '#6EF195',
      light1: '#9AF5B5',
      light2: '#C7F8D4',
      light3: '#F3FCF4'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 540,
      md: 768,
      lg: 1024,
      xl: 1280,
      xxl: 1440
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body {
        }
        
      `
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          body1: 'span',
          body2: 'span'
        }
      }
    }
  }
});

import { styled } from '@mui/material';

export const ContentWrapper = styled('div', {
  name: 'ContentWrapper'
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.primary.light3
}));

export const IconWrapper = styled('div', {
  name: 'IconWrapper'
})(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '4px',
  width: 'fit-content',
  height: '48px'
}));

export const Column = styled('div', {
  name: 'Column'
})(({ theme }) => ({
  display: 'flex',
  flex: 1,
  minWidth: 0,
  gap: theme.spacing(1.5),
  alignItems: 'center',
  '& .flex-column': {
    minWidth: 0,
    flex: 1
  }
}));
export const Divider = styled('div', {
  name: 'Divider'
})(({ theme }) => ({
  borderLeft: 'dashed 3px',
  margin: '0 24px',
  borderColor: theme.palette.primary.light2
}));

import { entitiesSelector } from '@core/selector/entitySelector';
import { SectionWrapper } from '@flight/pages/flight-detail/styled';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import useEntityFullfil from 'src/hooks/useEntityFullfil';
import { EmptyBox } from '../styled';
import PassengerActionHeader from './ActionHeader';
import PassengerFlightHeader from './TableHeader ';
import PassengerItem from './PassengerItem';
import { useFlightDetail } from '@flight/context/flightDetailContext';
import PassengerItemSkeleton from './PassengerItem/PassengerSkeleton';

const PassengerSection = () => {
  const { legData } = useFlightDetail();
  const { id } = legData;

  // GET LIST PASSENGER
  const [data, loading] = useEntityFullfil(
    'flight',
    '/flight-passenger',
    {
      flight_line_id: id,
      limit: undefined
    },
    false,
    'passenger'
  );

  // GET DATA NOT NORMALIZE FROM REDUX
  const passenger = useSelector(state =>
    entitiesSelector(state, 'flight', data.data ?? [], 'passenger')
  );

  return (
    <SectionWrapper>
      <PassengerActionHeader passenger={passenger} loading={loading} />
      {passenger?.length || loading ? (
        <>
          <PassengerFlightHeader />
          <Box className="flex-column" sx={{ gap: 2 }}>
            {loading
              ? Array(2)
                  .fill(0)
                  .map((_: any, index: number) => (
                    <PassengerItemSkeleton key={index} />
                  ))
              : passenger?.map((item: Record<string, any>, index: number) => {
                  return <PassengerItem data={item} key={index} />;
                })}
          </Box>
        </>
      ) : (
        <EmptyBox>
          <Typography variant="body2" color="neutral.dark1">
            No passenger is assigned to this leg.
          </Typography>
        </EmptyBox>
      )}
    </SectionWrapper>
  );
};

export default PassengerSection;

import { useEffect } from 'react';

export default function useFadeScroll({
  scrollRef,
  size = 'small'
}: {
  scrollRef?: React.MutableRefObject<HTMLDivElement>;
  size?: 'medium' | 'small';
}) {
  useEffect(() => {
    const node = scrollRef?.current;
    node.classList.toggle('fade-scroll');
    setClasses(node);
    function setClasses(el: HTMLDivElement) {
      const isScrollable = el.scrollHeight > el.clientHeight;

      // GUARD: If element is not scrollable, remove all classes
      if (!isScrollable) {
        el.classList.remove(
          `is-bottom-overflowing-${size}`,
          `is-top-overflowing-${size}`
        );
        return;
      }

      // Otherwise, the element is overflowing!
      // Now we just need to find out which direction it is overflowing to (can be both).
      // One pixel is added to the height to account for non-integer heights.
      const isScrolledToBottom =
        el.scrollHeight < el.clientHeight + el.scrollTop + 1;
      const isScrolledToTop = isScrolledToBottom ? false : el.scrollTop === 0;
      el.classList.toggle(`is-bottom-overflowing-${size}`, !isScrolledToBottom);
      el.classList.toggle(`is-top-overflowing-${size}`, !isScrolledToTop);
    }

    const bounce = () => setClasses(node);

    if (node) {
      node.addEventListener('scroll', bounce);

      return () => node.removeEventListener('scroll', bounce);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return;
}

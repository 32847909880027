import { styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'ConfirmDialogWrapper' })(
  ({ theme }) => ({
    overflow: 'hidden',
    padding: '32px 32px',
    margin: 'auto',
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    width: '648px',
    backgroundColor: theme.palette.neutral.white
  })
);

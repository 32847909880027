import { ClientDetail, ClientList } from '@client/index';
import { CrewDetail, CrewList } from '@crew/index';
import { ListLayout, MainLayout, RegisterLayout } from '@common/layouts';
import { USER_LEVEL } from '@constant/user_level';
import { FlightList } from '@flight/index';
import FlightDetail from '@flight/pages/flight-detail';
import { RegisterPage } from '@register/index';
import DefaultNavigate from '@utils/authentication/DefaultNavigate';
import { uniqueId } from 'lodash';
import { createBrowserRouter } from 'react-router-dom';
import DialogContext from '@common/layouts/DialogContext';
import Page404 from '@core/pages/404Page';

const permissionConvertor = (id: string, levels: number[]) => {
  return JSON.stringify({ [id]: levels.join('-') });
};

const router = createBrowserRouter([
  {
    element: <DialogContext />,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <DefaultNavigate />
          },
          {
            element: <ListLayout />,
            children: [
              {
                path: '/clients',
                element: <ClientList />,
                id: permissionConvertor(uniqueId(), [
                  USER_LEVEL.SUPERUSER,
                  USER_LEVEL.BROKER
                ])
              },
              {
                path: '/flights',
                element: <FlightList />,
                id: permissionConvertor(uniqueId(), [
                  USER_LEVEL.SUPERUSER,
                  USER_LEVEL.CLIENT,
                  USER_LEVEL.BROKER,
                  USER_LEVEL.FBO,
                  USER_LEVEL.OPERATOR
                ])
              }
              // {
              //   path: '/flights/fbo',
              //   element: <FlightListFBO />,
              //   id: permissionConvertor(uniqueId(), [USER_LEVEL.FBO])
              // }
            ]
          },
          {
            path: '/client/:id',
            element: <ClientDetail />,
            id: permissionConvertor(uniqueId(), [
              USER_LEVEL.SUPERUSER,
              USER_LEVEL.BROKER
            ])
          },
          {
            element: <ListLayout />,
            children: [
              {
                path: '/crews',
                element: <CrewList />,
                id: permissionConvertor(uniqueId(), [
                  USER_LEVEL.SUPERUSER,
                  USER_LEVEL.OPERATOR
                ])
              }
            ]
          },
          {
            path: '/crew/:id',
            element: <CrewDetail />,
            id: permissionConvertor(uniqueId(), [
              USER_LEVEL.SUPERUSER,
              USER_LEVEL.OPERATOR
            ])
          },
          {
            path: '/flight/:id',
            element: <FlightDetail />,
            id: permissionConvertor(uniqueId(), [
              USER_LEVEL.SUPERUSER,
              USER_LEVEL.CLIENT,
              USER_LEVEL.BROKER,
              USER_LEVEL.FBO,
              USER_LEVEL.OPERATOR
            ])
          },
          {
            path: '*',
            element: <Page404 />
          }

          // {
          //   path: '/flights/mayfly',
          //   element: <MayFlyFBO />,
          //   id: permissionConvertor(uniqueId(), [USER_LEVEL.FBO])
          // }
        ]
      },
      {
        element: <RegisterLayout />,
        children: [
          {
            path: '/register',
            element: <RegisterPage />
          }
        ]
      }
    ]
  }
]);

export default router;

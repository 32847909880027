import { Button, styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'TopBarWrapper' })(
  ({ theme }) => ({
    width: 'auto',
    padding: '40px 64px 0px 64px',
    left: '96px',
    right: '0px',
    backgroundColor: theme.palette.neutral.light3,
    zIndex: 3,
    [theme.breakpoints.down('lg')]: {
      padding: '40px 24px 0px 24px'
    },
    [theme.breakpoints.down('md')]: {
      left: 0,
      padding: '24px 24px 0px 24px'
    }
  })
);

export const Header = styled('div', { name: 'Header' })(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const Title = styled('div', { name: 'Title' })(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontSize: '32px',
  lineHeight: 1.25,
  letterSpacing: '0px',
  fontWeight: 600
}));

export const MainButton = styled(Button, { name: 'MainButton' })(
  ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      '.buttonText': {
        display: 'none'
      },
      span: {
        margin: 0
      }
    }
  })
);
export const ToolBarWrapper = styled('div', { name: 'ToolBarWrapper' })(
  ({ theme }) => ({
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '16px'
    }
  })
);
export const SearchBar = styled('div', { name: 'SearchBar' })(({ theme }) => ({
  flex: 1
}));
export const DropdownWrapper = styled('div', { name: 'DropdownWrapper' })(
  ({ theme }) => ({
    width: '288px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  })
);
export const DropdownItemWrapper = styled('div', {
  name: 'DropdownItemWrapper'
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '8px'
}));

export const Counter = styled('div')(({ theme }) => ({
  color: theme.palette.neutral.dark2,
  fontSize: '14px',
  fontFamily: `'Open Sans'`
}));

import { styled } from '@mui/material';

export const CrewWrapper = styled('div', {
  name: 'PocWrapper'
})(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.neutral.light3
}));


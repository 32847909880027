import { FLIGHT_FILTER_FUTURE_DAYS } from '@constant/constant';
import FlightFilterBar from '@flight/components/flight-list/FlightFilterBar';
import FlightTableBlock from '@flight/components/flight-list/FlightTable';
import FlightPagingBar from '@flight/components/flight-list/FlightTable/FlightPagingBar';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import useEntityFullfil from 'src/hooks/useEntityFullfil';
import { Wrapper } from './styled';

const FlightList = () => {
  //STATE
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams({
    page: '1',
    from_date: dayjs().format('YYYY-MM-DD'),
    to_date: dayjs().add(FLIGHT_FILTER_FUTURE_DAYS, 'day').format('YYYY-MM-DD')
  });
  const page = parseInt(searchParams.get('page'));
  const startDate = searchParams.get('from_date');
  const endDate = searchParams.get('to_date');
  const assignee = searchParams.get('assignee');
  const status = searchParams.get('status');

  const params = useMemo(() => {
    return {
      ...Object.fromEntries(searchParams.entries()),
      limit: 10
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'flight/clearPaging',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //GET DATA
  const [data, loading] = useEntityFullfil(
    'flight',
    '/flights',
    params,
    false,
    'flight'
  );

  return (
    <Wrapper sx={{ ml: -8 }}>
      <FlightFilterBar />
      <Box sx={{ flex: 1, mr: 2 }} className="flex-column">
        <Box
          sx={{ pb: 2, pr: 2, alignItems: 'center', pl: 8 }}
          className="flex-space-between-row"
        >
          <FlightPagingBar
            page={page}
            startDate={startDate}
            endDate={endDate}
            data={data.meta}
            reloadWhen={[startDate, endDate, assignee, status]}
          />
        </Box>
        <Box
          sx={{ overflow: 'auto', flex: 1, pt: 1, pl: 8, minHeight: '648px' }}
        >
          <FlightTableBlock loading={loading} data={data} />
        </Box>
      </Box>
    </Wrapper>
  );
};

export default FlightList;

import { styled } from '@mui/material';

export const CrewContent = styled('div', { name: 'CrewContent' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    background: '#fff',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3)
  })
);

export const CrewMedical = styled('div', { name: 'CrewMedical' })(
  ({ theme }) => ({
    width: '288px',
    height: '192px',
    position: 'relative',
    '.crew-preview': {
      display: 'none'
    },
    '&:hover': {
      '.crew-preview': {
        display: 'initial'
      }
    }
  })
);

export const CrewImage = styled('img', { name: 'CrewImage' })(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(1),
  objectFit: 'cover'
}));

export const CrewPreview = styled('div', { name: 'CrewPreview' })(
  ({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.primary.dark3,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    opacity: 0.6,
    cursor: 'pointer'
  })
);

export const PilotImageWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.neutral.light3,
  position: 'relative',
  padding: theme.spacing(2),
  width: '320px',
  height: '260px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderRadius: theme.spacing(1),
  '.crew-preview': {
    display: 'none'
  },
  '&:hover': {
    '.crew-preview': {
      display: 'initial'
    }
  }
}));

export const PilotImageTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.dark3,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  fontFamily: 'Open Sans'
}));

export const PilotImage = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  height: 'calc(260px - 70px)',
  borderRadius: theme.spacing(1)
}));

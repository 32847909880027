import Icon from '@common/components/Icon';
import { CrewImage, CrewMedical, CrewPreview } from './styled';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import PreviewCrewMedical from '@common/dialog/PreviewCrewMedical';
import { get } from 'lodash';
import { Box } from '@mui/material';

const Medical = ({ initialValues }) => {
  const onPreview = (initialIndex: number): void => {
    DialogJSX.open({
      component: PreviewCrewMedical,
      props: {
        title: 'View medical documentation ',
        cancelButtonLabel: 'Close',
        images: get(initialValues, 'medical_documents', []).map(document => ({
          url: document.document_url,
          alt: document.document_name
        })),
        initialIndex
      },
      inMobile: 'full'
    });
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
      {get(initialValues, 'medical_documents', []).map((document, index) => (
        <CrewMedical key={document.customer_id}>
          <CrewImage src={document.document_url} alt={document.document_name} />
          <CrewPreview
            onClick={() => onPreview(index)}
            className="crew-preview"
          >
            <Icon icon="icon-Expand" color="white" />
          </CrewPreview>
        </CrewMedical>
      ))}
    </Box>
  );
};

export default Medical;

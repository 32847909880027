import { styled } from '@mui/material';

export const CrewBanner = styled('div', { name: 'CewBanner' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#fff',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3)
}));

export const CrewBannerLeft = styled('div', { name: 'CewBannerLeft' })(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '32px',
  width: '80%',
}));

export const CrewBannerLeftInfo = styled('div', { name: 'CrewBannerLeftInfo' })(
  () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  })
);

export const CrewBannerLeftName = styled('div', { name: 'CrewBannerLeftName' })(
  ({ theme }) => ({
    color: theme.palette.primary.dark2,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: '0.15px',
    maxWidth: '219px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical'
  })
);

export const CrewBannerLeftEmail = styled('span', {
  name: 'CrewBannerLeftEmail'
})(({ theme }) => ({
  color: theme.palette.primary.dark2,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  maxWidth: '250px',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': '1',
  '-webkit-box-orient': 'vertical'
}));

export const CrewBannerDivider = styled('div', { name: 'CrewBannerDivider' })(
  ({ theme }) => ({
    background: theme.palette.neutral.light1,
    width: '1px',
    height: '22px'
  })
);

export const CrewBannerRight = styled('div', { name: 'CrewBannerRight' })(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.positive.dark1}`,
    color: theme.palette.positive.dark1,
    background: theme.palette.positive.light3,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.14px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  })
);

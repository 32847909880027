import { Button } from '@mui/material';
import { Image404 } from './Image404';
import { Wrapper } from './styled';
import Icon from '@common/components/Icon';
import { useNavigate } from 'react-router';

export default function Page404(props: any) {
  const navigate = useNavigate();
  const backHome = () => {
    navigate('/');
  };
  return (
    <Wrapper>
      <Image404 />
      <Button
        onClick={backHome}
        size="large"
        variant="contained"
        color="secondary"
        startIcon={<Icon icon="icon-previous-arrow" />}
      >
        Go back
      </Button>
    </Wrapper>
  );
}

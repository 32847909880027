import { get } from 'lodash';
import { createSelector } from 'reselect';

const getListEntity = (
  state: any,
  paging: string,
  module: string,
  resource?: string
) => {
  if (resource)
    return get(state, [module, resource, 'paging', paging], {
      data: []
    });

  return get(state, [module, 'paging', paging], {
    data: []
  });
};
export const listEntitySelector = createSelector(getListEntity, data => data);

const getEntityById = (
  state: any,
  module: string,
  id: number | string,
  resource?: string
) => {
  if (resource) return get(state, [module, resource, resource, id], {});
  return get(state, [module, module, id], {});
};
export const entitySelector = createSelector(getEntityById, data => data);

const getEntityByIds = (
  state: any,
  module: string,
  ids: (number | string)[],
  resource?: string
) => {
  return ids.map(id => {
    if (resource) {
      return get(state, [module, resource, resource, id], {});
    }
    return get(state, [module, module, id], {});
  });
};
export const entitiesSelector = createSelector(
  (state, module, ids, resource) =>
    getEntityByIds(state, module, ids, resource),
  data => data
);

import { styled } from '@mui/material';

export const ContentWrapper = styled('div', {
  name: 'ContentWrapper'
})(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(4)
}));

export const StaticWrapper = styled('div', {
  name: 'StaticWrapper'
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.primary.light3
}));

export const IconWrapper = styled('div', {
  name: 'IconWrapper'
})(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '4px',
  width: 'fit-content',
  height: '48px',
  marginRight: theme.spacing(1.5)
}));

export const LoadingLayer = styled('div', {
  name: 'LoadingLayer'
})(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1),
  backgroundColor: 'rgb(238, 247, 252,0.5)'
}));

export const Column = styled('div', {
  name: 'LeftColumn'
})(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center'
}));
export const Divider = styled('div', {
  name: 'Divider'
})(({ theme }) => ({
  borderLeft: 'dashed 3px',
  margin: '0 24px',
  borderColor: theme.palette.primary.light2
}));

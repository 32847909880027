import { clientsReducer } from '@client/reducer';
import clientSaga from '@client/saga';
import flightSaga from '@flight/saga';
import crewSaga from '@crew/saga';
import { coreReducer } from '@core/reducer';
import coreSaga from '@core/saga';
import { flightsReducer } from '@flight/reducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import registerSaga from '@register/saga';
import { userReducer } from '@user/reducer';
import userSaga from '@user/saga';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { crewReducer } from '@crew/reducer';

const sagaMiddleware = createSagaMiddleware();
const rootSagas = [
  coreSaga,
  userSaga,
  clientSaga,
  registerSaga,
  flightSaga,
  crewSaga
];
function* rootSaga() {
  try {
    yield all(rootSagas.flat());
  } catch (error) {}
}
const reducer = combineReducers({
  core: coreReducer.reducer,
  user: userReducer.reducer,
  client: clientsReducer.reducer,
  flight: flightsReducer.reducer,
  crew: crewReducer.reducer
});

const rootReducer = (state: any, action: any) => {
  const essentialReducer = { core: state?.core, user: state?.user };

  if (action.type === 'USER_LOGOUT') {
    return reducer(undefined, action);
  }
  if (action.type === 'CLIENTS_INIT_STATE') {
    return reducer({ ...essentialReducer, client: state.client }, action);
  }
  if (action.type === 'FLIGHTS_INIT_STATE') {
    return reducer({ ...essentialReducer, flight: state.flight }, action);
  }
  return reducer(state, action);
};
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: false
    }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

export default store;

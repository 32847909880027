import Icon from '@common/components/Icon';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { useField } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { CustomActionBar, CustomDay, CustomToolBar } from './Elements';
import classNames from 'classnames';
import { FLIGHT_FILTER_FUTURE_DAYS } from '@constant/constant';

function DateRangePickerField({ config, name }) {
  const { label, component, variant, disabled, fullWidth, ...restConfig } =
    config;

  const [field, , { setValue }] = useField(name ?? 'date');
  const [startDate, setStartDate] = useState(field.value.startDate ?? dayjs());
  const [datesPicked, setDatesPicked] = useState(0);
  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState(
    field.value.endDate ?? dayjs().add(FLIGHT_FILTER_FUTURE_DAYS, 'day')
  );
  const [value, setDateValue] = useState(field.value.startDate ?? dayjs());

  const ref = useRef(null);

  useEffect(() => {
    if (open) {
      setStartDate(field.value.startDate ?? dayjs());
      setEndDate(
        field.value.endDate ?? dayjs().add(FLIGHT_FILTER_FUTURE_DAYS, 'day')
      );
      setDatesPicked(0);
    }
  }, [field.value.endDate, field.value.startDate, open]);

  const onPickDate = dateSelected => {
    const date = dayjs(dateSelected);
    if (date.isBefore(startDate)) {
      setStartDate(date);
      setDatesPicked(1);
      return;
    }
    setDatesPicked(datesPicked + 1);
    if (datesPicked % 2 !== 0) {
      setEndDate(date);
    } else {
      setStartDate(date);
      if (date.isAfter(endDate)) {
        setEndDate(null);
        setDatesPicked(1);
        return;
      }
    }
  };

  const clickOutside = e => {
    if (!open) return;
    ref.current.click();
    setDateValue(field.value.startDate);
  };

  return (
    <ClickAwayListener onClickAway={clickOutside}>
      <Box>
        <DatePicker
          clearable
          autoFocus={true}
          dayOfWeekFormatter={weekday =>
            `${weekday.format('ddd').toUpperCase()}`
          }
          closeOnSelect={false}
          open={open}
          defaultCalendarMonth={field.value.startDate}
          disableHighlightToday={true}
          views={['year', 'month', 'day']}
          defaultValue={value}
          slots={{
            calendarHeader: CustomToolBar,
            actionBar: CustomActionBar,
            day: CustomDay
          }}
          onYearChange={e => {}}
          showDaysOutsideCurrentMonth={true}
          onChange={() => {}}
          {...restConfig}
          slotProps={{
            actionBar: {
              actions: ['cancel', 'accept'],
              startDate,
              endDate,
              setValue,
              setOpen,
              cancelRef: ref
            },
            toolbar: {},
            textField: {
              sx: { maxWidth: 0, p: 0, mt: 6 },
              InputProps: {
                endAdornment: null,
                sx: {
                  p: 0,
                  ml: -1.25,
                  opacity: 0,
                  maxHeight: 0,
                  border: 'none',
                  '& fieldset': { border: 'none', maxWidth: 0, p: 0 }
                }
              },
              inputProps: {
                sx: { p: 0, border: 'none' }
              }
            },
            day: {
              startDate,
              endDate,
              onPickDate
            }
          }}
        />
        <TextField
          value={`${
            field.value.startDate
              ? field.value.startDate.format('DD MMM YYYY')
              : dayjs().format('DD MMM YYYY')
          } - ${
            field.value.endDate
              ? field.value.endDate.format('DD MMM YYYY')
              : dayjs()
                  .add(FLIGHT_FILTER_FUTURE_DAYS, 'day')
                  .format('DD MMM YYYY')
          }`}
          size="small"
          fullWidth
          onClick={() => {
            setOpen(prev => !prev);
          }}
          inputProps={{ readOnly: true, sx: { cursor: 'pointer' } }}
          InputProps={{
            endAdornment: (
              <div
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  pointerEvents: 'none',
                  alignItems: 'center',
                  right: 16,
                  display: 'flex'
                }}
              >
                <Icon
                  icon="icon-down"
                  size="sm"
                  className={classNames({ 'icon-flip-y': open })}
                />
              </div>
            )
          }}
        />
      </Box>
    </ClickAwayListener>
  );
}

export default DateRangePickerField;

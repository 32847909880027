import { TextFieldForm } from '@common/formElement';
import { Box, Grid } from '@mui/material';

const Identify = ({ initialValues, sxDisabled }) => {
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="firstName"
              config={{
                label: 'First name',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="middleName"
              config={{
                label: 'Middle name',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="lastName"
              config={{
                label: 'Last name',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="date_of_birth"
              config={{
                label: 'Date of birth',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="country"
              config={{
                label: 'Issuing country',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="passport_number"
              config={{
                label: 'Passport licence number',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="passport_issue_date"
              config={{
                label: 'Valid from',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldForm
              name="passport_expiry_date"
              config={{
                label: 'Valid until',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Identify;

import { Box, Button, Grid, Typography } from '@mui/material';
import Footer from '../ActionFooter';
import { Wrapper } from './styled';
import { useRef } from 'react';
import useFadeScroll from 'src/hooks/useFadedScroll';
import CateringItem from './CateringItem';
import Icon from '@common/components/Icon';
import { exportToCSV, getName } from '@helper/stringFunction';

export default function PassengerCatering(props: any) {
  const { closeDialog, cancelButtonLabel, passenger } = props;
  const ref = useRef(null);
  useFadeScroll({ scrollRef: ref, size: 'small' });
  const onExport = () => {
    const data = [];
    data.push({ Name: 'Catering choice' });
    passenger.forEach(item => {
      data.push({
        [getName(item.customer)]: item.customer.caterings
          .map(item => item.catering_name)
          .join(', ')
      });
    });
    exportToCSV('Catering for flight.csv', data);
  };
  return (
    <>
      <Wrapper sx={{ width: 600 }}>
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          color="inherit"
          onClick={onExport}
          startIcon={<Icon icon="icon-export" color="neutral.dark3" />}
        >
          Export
        </Button>
        <Grid container>
          <Grid container item sx={{ px: 2 }}>
            <Grid item xs={6}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Catering choices
              </Typography>
            </Grid>
          </Grid>

          <Box
            ref={ref}
            sx={{
              mt: 2,
              mb: 1.5,
              width: '100%',
              maxHeight: '400px',
              overflow: 'auto',
              borderRadius: '4px'
            }}
          >
            {passenger.map((item: Record<string, any>, index: number) => {
              return (
                <CateringItem
                  customer={item.customer}
                  index={index}
                  key={index}
                />
              );
            })}
          </Box>
        </Grid>
        <Footer
          cancelButtonLabel={cancelButtonLabel}
          closeDialog={closeDialog}
        />
      </Wrapper>
    </>
  );
}

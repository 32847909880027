import { styled } from '@mui/material';

export const Wrapper = styled('div', { name: 'ClientInFlightDialogWrapper' })(
  ({ theme }) => ({
    overflow: 'hidden',
    padding: '24px 32px',
    '.MuiGrid-item': {
      display: 'flex',
      alignItems: 'center'
    }
  })
);

import Icon from '@common/components/Icon';
import { Box, IconButton } from '@mui/material';
import { IconWrapper, Wrapper } from './styled';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import TruncatedText from '@common/components/TruncatedText';

const LegStatus = ({
  icon = 'icon-direction',
  complete = false,
  buttonIcon = 'icon-Map-filled',
  activeColor = 'positive.dark1',
  backgroundActiveColor = 'positive.light3',
  title = 'Direction',
  subtitle = null,
  onClick = () => {
    DialogJSX.comingsoon();
  }
}: Record<string, any>) => {
  return (
    <Wrapper>
      <IconWrapper
        sx={{
          backgroundColor: complete ? backgroundActiveColor : 'neutral.light3'
        }}
      >
        <Icon
          icon={icon}
          sx={{ color: complete ? activeColor : 'neutral.main' }}
        />
      </IconWrapper>
      <Box
        className="flex-column"
        sx={{ alignItems: 'flex-start', flex: 1, minWidth: 0, pr: 2 }}
      >
        <TruncatedText variant="subtitle2" color="neutral.dark3">
          {title}
        </TruncatedText>
        <TruncatedText
          variant="body2"
          color={complete ? 'neutral.dark3' : 'neutral.dark1'}
        >
          {subtitle ? subtitle : complete ? 'Data added' : 'No data'}
        </TruncatedText>
      </Box>
      {buttonIcon ? (
        <Box className="flex-column" sx={{ alignItems: 'flex-end' }}>
          <IconButton color="info" onClick={onClick}>
            <Icon
              icon={complete ? buttonIcon : 'icon-Plus-filled'}
              sx={{ color: 'primary.dark2' }}
              size="sm"
            />
          </IconButton>
        </Box>
      ) : null}
    </Wrapper>
  );
};

export default LegStatus;

import { Backdrop, CircularProgress } from '@mui/material';

const LoadingProgress = () => (
  <Backdrop sx={theme => ({ zIndex: theme.zIndex.drawer + 1 })} open>
    <CircularProgress
      disableShrink
      thickness={3}
      sx={{
        animationDuration: '400ms'
      }}
    />
  </Backdrop>
);

export default LoadingProgress;

import { Tab, Tabs } from '@mui/material';
import { CrewContent } from './styled';
import { Fragment, useMemo, useState } from 'react';
import Identify from './Identify';
import PilotLicense from './PilotLicense';
import Medical from './Medical';
import { Formik } from 'formik';

export default function Banner({ data }) {
  const [tabValue, setTabValue] = useState(0);
  const sxDisabled = {
    '.MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
      '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87)',
      backgroundColor: '#F5F7FA'
    },
    '.MuiInputBase-root.MuiFilledInput-root.Mui-disabled::before': {
      'border-bottom': '2px solid #CCD4DC'
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const isAwaiting = useMemo(() => data.status === 1, [data]);

  const messageAwaiting = useMemo(
    () =>
      'When this crew has accepted your invitation their information will be displayed here.',
    []
  );

  return (
    <CrewContent>
      <Tabs onChange={handleChange} value={tabValue}>
        <Tab disableRipple label="Identity check information" value={0} />
        <Tab disableRipple label="Pilot's licence information" value={1} />
        <Tab
          disableRipple
          label="Medical documentation information "
          value={2}
        />
      </Tabs>
      {isAwaiting ? (
        messageAwaiting
      ) : (
        <Formik
          initialValues={data}
          onSubmit={() => {
            //
          }}
        >
          {({ initialValues }) => (
            <Fragment>
              {tabValue === 0 && (
                <Identify
                  initialValues={initialValues}
                  sxDisabled={sxDisabled}
                />
              )}
              {tabValue === 1 && (
                <PilotLicense
                  initialValues={initialValues}
                  sxDisabled={sxDisabled}
                />
              )}
              {tabValue === 2 && <Medical initialValues={initialValues} />}
            </Fragment>
          )}
        </Formik>
      )}
    </CrewContent>
  );
}

import * as Yup from 'yup';

export const AddLuggageSchema = Yup.object().shape({
  type_of_luggage: Yup.number().required(''),
  weight: Yup.string()
    .required('')
    .matches(/^[0-9]+$/, 'The weight is invalid')
    .test(
      'is-valid-weight',
      'Weight must be greater than 0 and less than or equal to 100',
      value => {
        const weight = parseFloat(value); // Convert string to number
        return weight > 0 && weight <= 100; // Ensure weight is within the desired range
      }
    )
});

export const AddPaxSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  type: Yup.string().required('Type is required'), // Make sure this is a number
  legs: Yup.array()
    .of(Yup.number().typeError(''))
    .min(1, 'Please select at least one flight leg before adding a passenger')
    .test(
      '',
      'Please select at least one flight leg before adding a passenger',
      value => value && value.some(num => num !== 0)
    )
    .required('Please select at least of 1 leg'),
  firstName: Yup.string().when('type', {
    is: (type: string | undefined) => type === 'guest',
    then: field => field.required('First name is required'),
    otherwise: field => field.notRequired()
  }),

  lastName: Yup.string().when('type', {
    is: (type: string | undefined) => type === 'guest',
    then: field => field.required('Last name is required'),
    otherwise: field => field.notRequired()
  })
});

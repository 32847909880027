export const Image404 = () => {
  return (
    <svg
      width="424"
      height="400"
      viewBox="0 0 424 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7822_56681)">
        <path
          d="M227.581 16.0541C206.533 15.8546 79.039 13.8792 75.2918 49.4465C71.704 83.5173 121.105 71.0363 121.583 86.1611C122.092 102.044 112.674 106.634 86.3739 105.626C74.9828 105.187 39.0158 109.227 38.4378 131.955C37.8598 154.552 78.0224 151.699 76.0392 170.455C74.8832 191.766 44.3875 196.405 32.3686 202.042C19.3132 208.876 -4.71463 220.15 0.80649 244.343C8.07164 272.398 54.2138 267.35 71.5944 270.812C83.2047 272.697 120.108 275.96 114.856 300.822C112.096 315.767 81.7995 316.635 87.5897 335.801C93.4497 353.719 146.279 357.261 156.982 357.849C201.41 360.254 298.957 364.185 342.408 345.837C358.384 339.163 372.485 331.122 370.851 307.137C370.054 285.717 358.523 282.584 377.957 266.033C390.245 255.378 413.764 256.236 412.369 225.148C411.622 208.377 368.758 201.413 369.715 183.854C371.21 156.268 423.82 179.694 424.009 130.907C424.129 100.458 328.416 117.488 329.393 87.3683C328.107 75.1467 349.165 69.9588 348.268 56.4203C348.767 34.7507 323.971 18.7179 227.591 16.0541H227.581Z"
          fill="#C7E9F9"
        />
        <path
          d="M144.066 236.951C143.967 237.011 143.837 237.05 143.688 237.05H70.4082C70.0794 237.05 69.7605 236.821 69.6807 236.502C68.8835 233.01 72.9097 230.276 75.6304 231.613C76.0091 229.837 77.7631 228.62 79.5968 228.839C78.5903 225.657 79.9855 222.434 83.3241 220.878C86.3936 219.451 89.2638 220.629 91.0875 222.953C92.1339 217.556 96.788 212.887 102.459 212.488C105.538 212.278 108.797 213.146 111.199 215.171C113.591 217.177 114.368 219.381 114.966 222.085C116.76 219.511 119.65 218.294 122.988 219.332C126.277 220.349 126.696 223.711 126.546 226.734C129.197 224.53 132.386 223.462 135.844 225.547C138.715 227.273 139.342 230.057 139.452 233.08C141.814 231.673 144.256 233.07 144.445 236.302C144.465 236.631 144.296 236.851 144.076 236.951H144.066Z"
          fill="#94D1F0"
        />
        <path
          d="M348.479 84.9243C348.479 98.9816 386.588 101.576 388.561 84.9243C390.545 68.263 348.479 63.8134 348.479 84.9243Z"
          fill="#94D1F0"
        />
        <path
          d="M40.8008 301.208C40.8008 315.276 87.9595 317.989 89.6737 301.208C91.7864 280.517 40.8008 276.586 40.8008 301.208Z"
          fill="#94D1F0"
        />
        <path
          d="M315.66 380.536C315.66 397.217 371.589 400.44 373.622 380.536C376.123 356.003 315.66 351.334 315.66 380.536Z"
          fill="#94D1F0"
        />
        <path
          d="M36.8145 85.9106C36.8145 95.0893 62.8056 96.8651 63.7523 85.9106C64.9183 72.402 36.8145 69.838 36.8145 85.9106Z"
          fill="#94D1F0"
        />
        <path
          d="M185.775 249.429C186.552 245.528 180.782 241.937 176.337 243.374C177.064 240.7 176.237 238.265 172.291 237.068C170.646 236.569 168.673 236.579 166.939 237.048C170.048 230.843 167.158 223.779 157.551 222.322C147.107 220.736 135.517 225.864 133.444 234.125C124.953 225.764 106.954 235.781 112.266 245.399C108.987 244.88 105.599 246.736 105.4 249.499C102.081 248.372 99.4599 251.764 101.513 254.677C101.662 254.887 101.971 255.026 102.27 255.026H190.927C191.156 255.026 191.336 254.967 191.465 254.887C191.605 254.807 191.714 254.687 191.774 254.518C192.781 251.644 189.063 248.911 185.775 249.439V249.429Z"
          fill="white"
        />
        <path
          d="M186.691 315.627C187.04 313.901 184.479 312.314 182.515 312.953C182.834 311.766 182.476 310.698 180.732 310.169C180.004 309.95 179.137 309.95 178.37 310.159C179.745 307.416 178.459 304.293 174.214 303.645C169.6 302.936 164.467 305.211 163.55 308.862C159.793 305.161 151.84 309.591 154.182 313.851C152.727 313.621 151.232 314.439 151.143 315.667C149.678 315.168 148.512 316.664 149.429 317.961C149.498 318.051 149.628 318.111 149.767 318.111H188.973C189.073 318.111 189.153 318.091 189.213 318.051C189.272 318.011 189.322 317.961 189.352 317.881C189.801 316.614 188.156 315.397 186.701 315.637L186.691 315.627Z"
          fill="#94D1F0"
        />
        <path
          d="M344.949 155.608C345.029 155.657 345.119 155.687 345.238 155.687H399.752C399.991 155.687 400.24 155.518 400.29 155.278C400.888 152.684 397.888 150.649 395.865 151.637C395.576 150.32 394.271 149.412 392.915 149.582C393.663 147.217 392.626 144.813 390.145 143.665C387.863 142.608 385.73 143.476 384.375 145.212C383.597 141.191 380.129 137.719 375.913 137.43C373.621 137.27 371.2 137.919 369.416 139.425C367.632 140.922 367.054 142.558 366.615 144.563C365.28 142.648 363.127 141.75 360.646 142.518C358.194 143.276 357.885 145.78 358.005 148.025C356.031 146.389 353.66 145.591 351.088 147.137C348.956 148.424 348.487 150.489 348.398 152.734C346.644 151.687 344.83 152.734 344.68 155.129C344.67 155.378 344.79 155.538 344.959 155.618L344.949 155.608Z"
          fill="white"
        />
        <path
          d="M202.867 57.6164C202.917 57.6464 202.986 57.6663 203.056 57.6663H239.89C240.05 57.6663 240.219 57.5566 240.259 57.387C240.668 55.6311 238.635 54.2543 237.269 54.9327C237.08 54.0448 236.193 53.4262 235.276 53.5359C235.784 51.9396 235.087 50.3134 233.402 49.5352C231.858 48.8169 230.423 49.4055 229.506 50.5828C228.978 47.8691 226.636 45.5246 223.785 45.325C222.241 45.2153 220.596 45.6543 219.39 46.6719C218.184 47.6795 217.796 48.787 217.497 50.1438C216.6 48.8468 215.145 48.2383 213.471 48.757C211.816 49.2659 211.607 50.9619 211.687 52.4784C210.351 51.371 208.757 50.8322 207.013 51.8798C205.568 52.7478 205.259 54.1445 205.199 55.661C204.013 54.9526 202.787 55.661 202.688 57.2772C202.688 57.4468 202.757 57.5566 202.877 57.6065L202.867 57.6164Z"
          fill="white"
        />
        <path
          d="M290.127 377.584C290.127 377.444 290.078 377.324 290.018 377.235C289.918 377.025 289.719 376.865 289.41 376.875C280.132 377.025 270.863 377.175 261.585 377.324C261.206 377.324 260.857 377.644 260.867 378.043C261.087 383.41 260.638 388.947 261.525 394.265C262.352 399.223 267.784 399.603 271.979 399.842C277.361 400.151 285.035 400.341 288.124 397.498C291.702 394.195 290.307 382.852 290.127 377.594V377.584Z"
          fill="#48B1E5"
        />
        <path
          d="M265.712 388.068C261.506 388.068 260.54 384.097 260.53 384.057C260.48 383.828 260.619 383.598 260.848 383.548C261.088 383.499 261.307 383.638 261.367 383.868C261.407 384.027 262.383 387.928 266.948 387.1C269.987 386.551 270.216 380.176 270.216 380.116C270.216 379.907 270.386 379.727 270.595 379.707C270.794 379.677 271.004 379.807 271.064 380.017C271.143 380.296 271.223 380.655 271.323 381.064C271.671 382.611 272.21 384.935 273.316 385.913C274.711 387.13 277.91 387.28 279.555 386.542C281.269 385.873 282.096 383.469 282.036 381.294C281.996 380.984 281.966 380.655 281.946 380.336C281.946 380.106 282.106 379.917 282.335 379.897C282.554 379.877 282.764 380.037 282.803 380.256C282.853 380.575 282.883 380.895 282.893 381.214C283.252 383.548 284.617 385.494 286.361 386.023C287.747 386.432 289.082 385.843 290.138 384.367C290.278 384.177 290.547 384.127 290.736 384.267C290.926 384.406 290.975 384.676 290.836 384.865C289.57 386.641 287.846 387.37 286.122 386.841C284.647 386.402 283.421 385.165 282.684 383.529C282.285 385.255 281.358 386.771 279.903 387.34C278 388.188 274.452 388.038 272.768 386.561C271.761 385.684 271.193 384.037 270.814 382.571C270.416 384.706 269.449 387.529 267.127 387.948C266.629 388.038 266.17 388.078 265.742 388.078L265.712 388.068Z"
          fill="white"
        />
        <path
          d="M274.86 374.988C271.91 374.988 261.904 375.118 260.808 377.383C259.343 380.555 275.627 380.306 278.776 380.206C293.685 379.717 295.459 374.24 274.87 374.988H274.86Z"
          fill="#0E5078"
        />
        <path
          d="M274.86 374.418C273.156 374.418 258.865 374.169 260.17 378.04C260.808 379.926 263.828 380.095 265.432 380.325C268.97 380.813 272.578 380.833 276.145 380.823C279.305 380.803 282.454 380.644 285.573 380.085C286.968 379.836 289.988 379.357 290.606 377.731C292.201 373.54 275.986 374.378 274.86 374.418C274.122 374.438 274.122 375.586 274.86 375.566C278.119 375.466 281.358 375.466 284.607 375.775C285.175 375.835 290.845 376.593 288.992 377.8C285.563 380.045 280.082 379.646 276.155 379.676C271.77 379.716 267.086 379.796 262.831 378.658C260.768 378.11 262.163 376.913 263.529 376.513C264.964 376.094 266.498 375.965 267.973 375.825C270.256 375.606 272.568 375.566 274.87 375.556C275.607 375.556 275.607 374.408 274.87 374.408L274.86 374.418Z"
          fill="white"
        />
        <path
          d="M290.327 343.384C290.138 354.668 289.739 365.952 289.59 377.226C289.59 377.774 290.437 377.774 290.447 377.226C290.596 365.952 291.005 354.658 291.184 343.384C291.194 342.835 290.337 342.835 290.327 343.384Z"
          fill="#48B1E5"
        />
        <path
          d="M258.686 343.193C259.035 354.616 259.184 366 260.38 377.373C260.44 377.912 261.297 377.922 261.237 377.373C260.041 366 259.892 354.616 259.543 343.193C259.523 342.644 258.666 342.644 258.686 343.193Z"
          fill="#48B1E5"
        />
        <path
          d="M274.123 345.107C275.08 356.62 273.884 368.173 274.881 379.686C274.93 380.235 275.787 380.235 275.738 379.686C274.731 368.173 275.937 356.62 274.98 345.107C274.93 344.558 274.073 344.558 274.123 345.107Z"
          fill="#48B1E5"
        />
        <path
          d="M334.465 245.101C330.868 235.972 324.24 226.884 313.866 225.008C313.786 224.998 313.716 224.998 313.647 224.988C313.587 224.988 313.537 224.958 313.477 224.958C305.734 224.17 301.229 231.902 298.498 237.818C293.585 248.513 291.433 260.495 289.499 271.989C285.712 294.606 281.806 319.389 288.433 341.806C288.792 343.044 290.446 343.203 291.134 342.156C298.219 331.251 312.839 327.52 322.187 318.82C331.954 309.731 336.917 296.442 338.502 283.582C340.126 270.472 339.349 257.502 334.465 245.101Z"
          fill="#48B1E5"
        />
        <path
          d="M257.35 281.685C253.911 263.078 245.789 244.471 236.421 227.98C236.092 227.411 235.564 227.221 235.056 227.291C234.847 227.291 234.627 227.351 234.398 227.461C224.98 232.309 219.918 241.598 216.748 251.196C213.34 261.492 211.556 274.042 213.141 284.817C215.184 298.765 223.057 311.086 233.431 320.614C241.922 328.436 252.496 333.494 260.09 342.433C260.937 343.431 262.622 342.593 262.612 341.406C262.412 321.482 260.987 301.289 257.36 281.675L257.35 281.685Z"
          fill="white"
        />
        <path
          d="M302.146 214.222C293.825 209.672 284.098 208.904 274.909 210.73C274.341 210.84 274.022 211.179 273.903 211.588C268.342 219.529 267.525 230.634 266.717 239.922C265.432 254.528 265.781 269.284 266.428 283.91C267.305 303.624 268.96 323.388 271.471 342.972C271.571 343.751 272.069 344.419 272.946 344.419H289.878C290.376 344.419 290.915 344.15 291.154 343.701C306.78 314.968 311.155 280.408 313.776 248.372C314.374 241.069 314.853 233.736 314.972 226.413C315.052 221.575 305.684 216.157 302.166 214.232L302.146 214.222Z"
          fill="white"
        />
        <path
          d="M278.707 216.397C276.057 201.102 249.089 213.304 241.953 218.532C235.356 223.361 233.572 227.93 233.143 235.313C232.436 247.614 233.103 259.985 234.658 272.197C236.801 288.968 241.046 305.659 247.863 321.213C249.936 325.952 252.208 330.581 254.7 335.121C255.826 337.176 258.307 343.701 260.739 344.359C264.317 345.327 269.23 344.409 272.937 344.409C273.675 344.409 274.123 343.97 274.293 343.441C274.352 343.302 274.402 343.142 274.412 342.962C276.744 315.077 278.588 287.122 279.545 259.157C280.023 245.13 281.119 230.314 278.707 216.397Z"
          fill="#48B1E5"
        />
        <path
          d="M258.297 345.059C269.22 347.095 280.75 346.965 291.663 345.059C294.782 344.521 293.457 340.33 290.348 340.879C280.352 342.615 269.618 342.745 259.613 340.879C256.503 340.3 255.188 344.481 258.297 345.059Z"
          fill="#48B1E5"
        />
        <path
          d="M157.511 73.0515C157.511 72.9916 157.491 72.9417 157.461 72.9018C157.411 72.812 157.332 72.7422 157.202 72.7422C153.186 72.812 149.17 72.8719 145.143 72.9417C144.984 72.9417 144.824 73.0814 144.834 73.251C144.934 75.5756 144.735 77.97 145.123 80.2747C145.482 82.4197 147.834 82.5893 149.648 82.6891C151.98 82.8287 155.309 82.9085 156.644 81.6714C158.199 80.2447 157.591 75.3262 157.511 73.0515Z"
          fill="#48B1E5"
        />
        <path
          d="M146.938 77.5994C145.114 77.5994 144.695 75.8834 144.695 75.8634C144.675 75.7637 144.735 75.6639 144.835 75.6439C144.934 75.624 145.034 75.6839 145.054 75.7836C145.074 75.8535 145.492 77.5395 147.476 77.1804C148.791 76.9409 148.891 74.1873 148.891 74.1574C148.891 74.0676 148.961 73.9878 149.05 73.9778C149.14 73.9678 149.23 74.0177 149.25 74.1075C149.28 74.2272 149.319 74.3869 149.359 74.5664C149.509 75.2349 149.738 76.2426 150.226 76.6716C150.834 77.2003 152.219 77.2602 152.927 76.9409C153.665 76.6516 154.033 75.614 154.003 74.6662C153.983 74.5265 153.973 74.3869 153.964 74.2472C153.964 74.1474 154.033 74.0676 154.133 74.0576C154.223 74.0576 154.322 74.1175 154.332 74.2173C154.352 74.3569 154.362 74.4866 154.372 74.6363C154.532 75.6439 155.12 76.492 155.877 76.7214C156.475 76.901 157.053 76.6416 157.511 76.0031C157.571 75.9233 157.691 75.9033 157.771 75.9632C157.85 76.0231 157.87 76.1428 157.81 76.2226C157.262 76.9908 156.515 77.3101 155.767 77.0806C155.13 76.891 154.591 76.3523 154.272 75.6439C154.103 76.3922 153.694 77.0507 153.067 77.2901C152.239 77.6593 150.705 77.5894 149.977 76.9509C149.539 76.5718 149.299 75.8535 149.13 75.2249C148.951 76.1528 148.532 77.3699 147.526 77.5595C147.306 77.5994 147.107 77.6194 146.928 77.6194L146.938 77.5994Z"
          fill="white"
        />
        <path
          d="M150.894 71.9354C149.618 71.9354 145.283 71.9952 144.804 72.9729C144.167 74.3497 151.222 74.24 152.588 74.2001C159.046 73.9906 159.813 71.6161 150.894 71.9453V71.9354Z"
          fill="#0E5078"
        />
        <path
          d="M150.894 71.6862C150.157 71.6862 143.968 71.5765 144.526 73.2526C144.805 74.0707 146.11 74.1405 146.808 74.2403C148.343 74.4498 149.907 74.4598 151.452 74.4598C152.817 74.4598 154.183 74.3799 155.538 74.1405C156.146 74.0308 157.452 73.8212 157.721 73.1229C158.408 71.3071 151.392 71.6663 150.904 71.6862C150.585 71.6962 150.585 72.195 150.904 72.185C152.309 72.1451 153.724 72.1352 155.13 72.2748C155.379 72.3048 157.83 72.624 157.033 73.1528C155.548 74.1205 153.176 73.9509 151.472 73.9709C149.569 73.9909 147.545 74.0208 145.702 73.5319C144.815 73.2925 145.413 72.7737 146.001 72.6041C146.619 72.4245 147.286 72.3646 147.924 72.3048C148.911 72.205 149.917 72.195 150.914 72.185C151.233 72.185 151.233 71.6862 150.914 71.6862H150.894Z"
          fill="white"
        />
        <path
          d="M157.6 58.2343C157.52 63.1229 157.341 68.0115 157.281 72.9002C157.281 73.1396 157.65 73.1396 157.65 72.9002C157.72 68.0115 157.889 63.1229 157.979 58.2343C157.979 57.9948 157.61 57.9948 157.61 58.2343H157.6Z"
          fill="#48B1E5"
        />
        <path
          d="M143.887 58.1561C144.036 63.1046 144.106 68.0332 144.624 72.9617C144.644 73.2012 145.023 73.2012 144.993 72.9617C144.475 68.0332 144.415 63.1046 144.256 58.1561C144.256 57.9167 143.877 57.9167 143.887 58.1561Z"
          fill="#48B1E5"
        />
        <path
          d="M150.575 58.9843C150.994 63.9727 150.465 68.981 150.904 73.9694C150.924 74.2089 151.293 74.2089 151.273 73.9694C150.834 68.981 151.362 63.9727 150.944 58.9843C150.924 58.7448 150.555 58.7448 150.575 58.9843Z"
          fill="#48B1E5"
        />
        <path
          d="M176.716 15.6638C175.161 11.7129 172.291 7.7721 167.787 6.95401C167.757 6.95401 167.727 6.95401 167.687 6.95401C167.657 6.95401 167.637 6.94403 167.617 6.93405C164.259 6.59484 162.305 9.93707 161.129 12.5111C159.007 17.1403 158.07 22.3283 157.233 27.3167C155.598 37.1139 153.904 47.8489 156.774 57.5663C156.934 58.0951 157.641 58.1749 157.94 57.716C161.01 52.997 167.348 51.3707 171.394 47.6095C175.63 43.6686 177.773 37.912 178.46 32.345C179.158 26.6682 178.829 21.0413 176.706 15.6737L176.716 15.6638Z"
          fill="#48B1E5"
        />
        <path
          d="M143.31 31.5087C141.815 23.4474 138.307 15.3862 134.241 8.24278C134.101 7.99336 133.872 7.91355 133.653 7.94348C133.563 7.94348 133.463 7.96343 133.364 8.02329C129.288 10.1284 127.085 14.1491 125.72 18.3094C124.245 22.769 123.477 28.2064 124.155 32.8755C125.042 38.9215 128.45 44.2491 132.945 48.3795C136.622 51.7716 141.207 53.9665 144.486 57.8375C144.854 58.2665 145.582 57.9073 145.582 57.3985C145.492 48.7686 144.874 40.0189 143.3 31.5187L143.31 31.5087Z"
          fill="white"
        />
        <path
          d="M162.713 2.28558C159.106 0.320149 154.9 -0.0190624 150.914 0.769105C150.665 0.818989 150.525 0.968641 150.475 1.13825C148.063 4.58024 147.715 9.38906 147.366 13.4097C146.808 19.735 146.957 26.1301 147.246 32.4654C147.625 41.0056 148.343 49.5657 149.429 58.0559C149.469 58.3951 149.688 58.6845 150.067 58.6845H157.402C157.621 58.6845 157.85 58.5647 157.95 58.3752C164.717 45.9241 166.62 30.9489 167.746 17.0712C168.005 13.9086 168.215 10.7359 168.264 7.55333C168.294 5.4582 164.238 3.10368 162.713 2.2756V2.28558Z"
          fill="white"
        />
        <path
          d="M152.557 3.22382C151.411 -3.40078 139.721 1.88693 136.632 4.15166C133.772 6.24679 132.994 8.2222 132.815 11.4248C132.506 16.7524 132.795 22.1099 133.473 27.3976C134.4 34.6607 136.243 41.8939 139.193 48.6283C140.09 50.6835 141.077 52.6888 142.153 54.6542C142.641 55.5422 143.718 58.3756 144.764 58.6549C146.319 59.074 148.442 58.6749 150.056 58.6749C150.375 58.6749 150.574 58.4853 150.644 58.2559C150.674 58.196 150.694 58.1262 150.694 58.0464C151.7 45.9644 152.498 33.8526 152.916 21.7408C153.126 15.6649 153.594 9.23983 152.557 3.21384V3.22382Z"
          fill="#48B1E5"
        />
        <path
          d="M143.718 58.9642C148.452 59.8522 153.445 59.7923 158.169 58.9642C159.524 58.7348 158.946 56.919 157.6 57.1485C153.275 57.9067 148.621 57.9566 144.286 57.1485C142.941 56.899 142.373 58.7148 143.718 58.9642Z"
          fill="#48B1E5"
        />
        <path
          d="M184.719 150.516H172.906V168H168.266V150.516H133.484V146.766L167.938 99.0938H172.906V146.156H184.719V150.516ZM168.266 146.156V119.812C168.266 117.875 168.266 116.203 168.266 114.797C168.297 113.391 168.328 112.125 168.359 111C168.391 109.844 168.422 108.734 168.453 107.672C168.484 106.609 168.516 105.516 168.547 104.391H168.266C167.391 105.891 166.562 107.281 165.781 108.562C165.031 109.812 164.062 111.234 162.875 112.828L138.688 146.156H168.266Z"
          fill="#0E5078"
        />
        <path
          d="M233.797 133.547C233.797 139.266 233.359 144.328 232.484 148.734C231.609 153.141 230.281 156.844 228.5 159.844C226.719 162.844 224.453 165.109 221.703 166.641C218.953 168.172 215.703 168.938 211.953 168.938C207.203 168.938 203.203 167.609 199.953 164.953C196.703 162.297 194.234 158.344 192.547 153.094C190.891 147.844 190.062 141.328 190.062 133.547C190.062 126.609 190.766 120.5 192.172 115.219C193.609 109.938 195.938 105.812 199.156 102.844C202.375 99.875 206.656 98.3906 212 98.3906C217.312 98.3906 221.562 99.8438 224.75 102.75C227.969 105.656 230.281 109.75 231.688 115.031C233.094 120.281 233.797 126.453 233.797 133.547ZM194.844 133.547C194.844 140.578 195.453 146.406 196.672 151.031C197.891 155.625 199.766 159.062 202.297 161.344C204.828 163.625 208.047 164.766 211.953 164.766C215.984 164.766 219.25 163.625 221.75 161.344C224.25 159.062 226.078 155.609 227.234 150.984C228.422 146.359 229.016 140.547 229.016 133.547C229.016 127.109 228.5 121.578 227.469 116.953C226.469 112.328 224.734 108.781 222.266 106.312C219.797 103.844 216.375 102.609 212 102.609C207.719 102.609 204.328 103.875 201.828 106.406C199.328 108.938 197.531 112.516 196.438 117.141C195.375 121.766 194.844 127.234 194.844 133.547Z"
          fill="#0E5078"
        />
        <path
          d="M291.406 150.516H279.594V168H274.953V150.516H240.172V146.766L274.625 99.0938H279.594V146.156H291.406V150.516ZM274.953 146.156V119.812C274.953 117.875 274.953 116.203 274.953 114.797C274.984 113.391 275.016 112.125 275.047 111C275.078 109.844 275.109 108.734 275.141 107.672C275.172 106.609 275.203 105.516 275.234 104.391H274.953C274.078 105.891 273.25 107.281 272.469 108.562C271.719 109.812 270.75 111.234 269.562 112.828L245.375 146.156H274.953Z"
          fill="#0E5078"
        />
        <path
          d="M196.402 80.8008C196.402 82.1211 196.234 83.3242 195.898 84.4102C195.562 85.4883 195.062 86.418 194.398 87.1992C193.742 87.9727 192.926 88.5703 191.949 88.9922C190.973 89.4141 189.84 89.625 188.551 89.625C187.223 89.625 186.062 89.4141 185.07 88.9922C184.086 88.5703 183.266 87.9688 182.609 87.1875C181.961 86.4062 181.473 85.4727 181.145 84.3867C180.824 83.3008 180.664 82.0977 180.664 80.7773C180.664 79.0273 180.953 77.4922 181.531 76.1719C182.109 74.8516 182.984 73.8242 184.156 73.0898C185.336 72.3555 186.812 71.9883 188.586 71.9883C190.281 71.9883 191.707 72.3516 192.863 73.0781C194.027 73.8047 194.906 74.8281 195.5 76.1484C196.102 77.4609 196.402 79.0117 196.402 80.8008ZM182.762 80.8008C182.762 82.2539 182.969 83.5117 183.383 84.5742C183.797 85.6367 184.43 86.457 185.281 87.0352C186.141 87.6133 187.23 87.9023 188.551 87.9023C189.879 87.9023 190.965 87.6133 191.809 87.0352C192.66 86.457 193.289 85.6367 193.695 84.5742C194.102 83.5117 194.305 82.2539 194.305 80.8008C194.305 78.5898 193.84 76.8633 192.91 75.6211C191.988 74.3711 190.547 73.7461 188.586 73.7461C187.258 73.7461 186.164 74.0312 185.305 74.6016C184.445 75.1719 183.805 75.9844 183.383 77.0391C182.969 78.0859 182.762 79.3398 182.762 80.8008Z"
          fill="#1D7FB3"
        />
        <path
          d="M211.004 82.9453C211.004 84 210.867 84.9414 210.594 85.7695C210.32 86.5977 209.926 87.2969 209.41 87.8672C208.895 88.4375 208.27 88.875 207.535 89.1797C206.809 89.4766 205.984 89.625 205.062 89.625C204.203 89.625 203.414 89.4766 202.695 89.1797C201.984 88.875 201.367 88.4375 200.844 87.8672C200.328 87.2969 199.926 86.5977 199.637 85.7695C199.355 84.9414 199.215 84 199.215 82.9453C199.215 81.5391 199.453 80.3438 199.93 79.3594C200.406 78.3672 201.086 77.6133 201.969 77.0977C202.859 76.5742 203.918 76.3125 205.145 76.3125C206.316 76.3125 207.34 76.5742 208.215 77.0977C209.098 77.6211 209.781 78.3789 210.266 79.3711C210.758 80.3555 211.004 81.5469 211.004 82.9453ZM201.23 82.9453C201.23 83.9766 201.367 84.8711 201.641 85.6289C201.914 86.3867 202.336 86.9727 202.906 87.3867C203.477 87.8008 204.211 88.0078 205.109 88.0078C206 88.0078 206.73 87.8008 207.301 87.3867C207.879 86.9727 208.305 86.3867 208.578 85.6289C208.852 84.8711 208.988 83.9766 208.988 82.9453C208.988 81.9219 208.852 81.0391 208.578 80.2969C208.305 79.5469 207.883 78.9688 207.312 78.5625C206.742 78.1562 206.004 77.9531 205.098 77.9531C203.762 77.9531 202.781 78.3945 202.156 79.2773C201.539 80.1602 201.23 81.3828 201.23 82.9453Z"
          fill="#1D7FB3"
        />
        <path
          d="M220.402 76.3125C222.004 76.3125 223.281 76.8633 224.234 77.9648C225.188 79.0664 225.664 80.7227 225.664 82.9336C225.664 84.3945 225.445 85.6211 225.008 86.6133C224.57 87.6055 223.953 88.3555 223.156 88.8633C222.367 89.3711 221.434 89.625 220.355 89.625C219.676 89.625 219.078 89.5352 218.562 89.3555C218.047 89.1758 217.605 88.9336 217.238 88.6289C216.871 88.3242 216.566 87.9922 216.324 87.6328H216.184C216.207 87.9375 216.234 88.3047 216.266 88.7344C216.305 89.1641 216.324 89.5391 216.324 89.8594V95.1328H214.367V76.5469H215.973L216.23 78.4453H216.324C216.574 78.0547 216.879 77.6992 217.238 77.3789C217.598 77.0508 218.035 76.793 218.551 76.6055C219.074 76.4102 219.691 76.3125 220.402 76.3125ZM220.062 77.9531C219.172 77.9531 218.453 78.125 217.906 78.4688C217.367 78.8125 216.973 79.3281 216.723 80.0156C216.473 80.6953 216.34 81.5508 216.324 82.582V82.957C216.324 84.043 216.441 84.9609 216.676 85.7109C216.918 86.4609 217.312 87.0312 217.859 87.4219C218.414 87.8125 219.156 88.0078 220.086 88.0078C220.883 88.0078 221.547 87.793 222.078 87.3633C222.609 86.9336 223.004 86.3359 223.262 85.5703C223.527 84.7969 223.66 83.9102 223.66 82.9102C223.66 81.3945 223.363 80.1914 222.77 79.3008C222.184 78.4023 221.281 77.9531 220.062 77.9531Z"
          fill="#1D7FB3"
        />
        <path
          d="M237.371 85.875C237.371 86.6953 237.164 87.3867 236.75 87.9492C236.344 88.5039 235.758 88.9219 234.992 89.2031C234.234 89.4844 233.328 89.625 232.273 89.625C231.375 89.625 230.598 89.5547 229.941 89.4141C229.285 89.2734 228.711 89.0742 228.219 88.8164V87.0234C228.742 87.2812 229.367 87.5156 230.094 87.7266C230.82 87.9375 231.562 88.043 232.32 88.043C233.43 88.043 234.234 87.8633 234.734 87.5039C235.234 87.1445 235.484 86.6562 235.484 86.0391C235.484 85.6875 235.383 85.3789 235.18 85.1133C234.984 84.8398 234.652 84.5781 234.184 84.3281C233.715 84.0703 233.066 83.7891 232.238 83.4844C231.418 83.1719 230.707 82.8633 230.105 82.5586C229.512 82.2461 229.051 81.8672 228.723 81.4219C228.402 80.9766 228.242 80.3984 228.242 79.6875C228.242 78.6016 228.68 77.7695 229.555 77.1914C230.438 76.6055 231.594 76.3125 233.023 76.3125C233.797 76.3125 234.52 76.3906 235.191 76.5469C235.871 76.6953 236.504 76.8984 237.09 77.1562L236.434 78.7148C235.902 78.4883 235.336 78.2969 234.734 78.1406C234.133 77.9844 233.52 77.9062 232.895 77.9062C231.996 77.9062 231.305 78.0547 230.82 78.3516C230.344 78.6484 230.105 79.0547 230.105 79.5703C230.105 79.9688 230.215 80.2969 230.434 80.5547C230.66 80.8125 231.02 81.0547 231.512 81.2812C232.004 81.5078 232.652 81.7734 233.457 82.0781C234.262 82.375 234.957 82.6836 235.543 83.0039C236.129 83.3164 236.578 83.6992 236.891 84.1523C237.211 84.5977 237.371 85.1719 237.371 85.875Z"
          fill="#1D7FB3"
        />
        <path
          d="M242.258 84.6328H240.98L240.441 72.2578H242.797L242.258 84.6328ZM240.207 88.1719C240.207 87.625 240.34 87.2383 240.605 87.0117C240.871 86.7773 241.203 86.6602 241.602 86.6602C241.992 86.6602 242.32 86.7773 242.586 87.0117C242.859 87.2383 242.996 87.625 242.996 88.1719C242.996 88.7109 242.859 89.1055 242.586 89.3555C242.32 89.5977 241.992 89.7188 241.602 89.7188C241.203 89.7188 240.871 89.5977 240.605 89.3555C240.34 89.1055 240.207 88.7109 240.207 88.1719Z"
          fill="#1D7FB3"
        />
      </g>
      <defs>
        <clipPath id="clip0_7822_56681">
          <rect width="424" height="400" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { listEntitySelector } from '@core/selector/entitySelector';
import { userInstance } from '@instance/userInstance';
import compactUrl from '@services/compactUrl';
import handleActionError from '@services/handleActionError';
import { put, select, takeEvery } from 'redux-saga/effects';

export function* entityFULLFIL(action: any): Record<string, any> {
  try {
    const { params, paging, module, apiUrl, resource } = action.payload;
    const pagingData = yield select(state =>
      listEntitySelector(state, paging, module)
    );
    if (pagingData?.next_cursor === 'end' || pagingData.loading) return;

    const isLoadMore = !!pagingData?.next_cursor?.length;
    yield put({
      type: `${module}/INIT`,
      payload: { paging, resource }
    });
    const response = yield userInstance.request({
      url: apiUrl,
      method: 'GET',
      params: {
        limit: 20,
        ...params,
        cursor: isLoadMore ? pagingData.next_cursor : undefined
      }
    });
    const data = response?.data?.data;
    yield put({
      type: `${module}/FULLFIL`,
      payload: { ...data, paging, resource }
    });
  } catch (err) {
    yield handleActionError(err);
  }
}

export function* entityREFRESH(action: any): Record<string, any> {
  const { module, data } = action.payload;
  yield put({
    type: `${module}/refreshPaging`,
    data
  });
}

export function* fetchEntityDetail(action: any): Record<string, any> {
  try {
    const { params, module, apiUrl, setLoading, resource } = action.payload;
    setLoading(true);
    const response = yield userInstance.request({
      url: compactUrl(apiUrl, { ...params }),
      method: 'GET'
    });
    const data = response?.data?.data;
    yield put({
      type: `${module}/fetchDetail`,
      payload: { data, resource }
    });
    setLoading(false);
  } catch (err) {
    const { setLoading } = action.payload;
    setLoading(false);
    yield handleActionError(err);
  }
}
export function* getData(action: any): Record<string, any> {
  const { setLoading, setData, setError, apiUrl, params } = action.payload;

  try {
    setLoading(true);
    const response = yield userInstance.request({
      url: apiUrl,
      params,
      method: 'GET'
    });
    const data = response?.data?.data;
    setData(data);
    setLoading(false);
  } catch (err) {
    setError(err);
    setLoading(false);
    yield handleActionError(err);
  }
}

const entitySaga = [
  takeEvery('@entity/FULLFIL', entityFULLFIL),
  takeEvery('@entity/REFRESH', entityREFRESH),
  takeEvery('@entity/fetchItem', fetchEntityDetail),
  takeEvery('@getData', getData)
];

export default entitySaga;

import { TextFieldForm } from '@common/formElement';
import { Box, Grid } from '@mui/material';
import {
  CrewPreview,
  PilotImage,
  PilotImageTitle,
  PilotImageWrapper
} from './styled';
import { get } from 'lodash';
import Icon from '@common/components/Icon';
import { DialogJSX } from '@utils/dialog/DialogUtils';
import PreviewCrewMedical from '@common/dialog/PreviewCrewMedical';
import { useMemo } from 'react';

const PilotLicense = ({ initialValues, sxDisabled }) => {
  const pilotImages = useMemo(
    () => [
      {
        title: 'Front side',
        image: get(initialValues, 'pilot_license.front_image_url'),
        alt: get(initialValues, 'pilot_license.front_image_name')
      },
      {
        title: 'Back side',
        image: get(initialValues, 'pilot_license.back_image_url'),
        alt: get(initialValues, 'pilot_license.back_image_name')
      }
    ],
    [initialValues]
  );

  const onPreview = (initialIndex: number): void => {
    DialogJSX.open({
      component: PreviewCrewMedical,
      props: {
        title: 'View pilot documentation ',
        cancelButtonLabel: 'Close',
        images: pilotImages.map(pilotImage => ({
          url: pilotImage.image,
          alt: pilotImage.alt
        })),
        initialIndex
      },
      inMobile: 'full'
    });
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.pilot_licence_number"
              config={{
                label: 'Licence issuing authority',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.licence_issuing_authority"
              config={{
                label: 'Pilot licence number',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.date_of_issue"
              config={{
                label: 'Date of Issue',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldForm
              name="pilot_license.expiration_date"
              config={{
                label: 'Expiration Date',
                disabled: true,
                sx: sxDisabled
              }}
              initialValues={initialValues}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1, display: 'flex', gap: '32px' }}>
        {pilotImages.map((pilotImage, index) => (
          <PilotImageWrapper key={pilotImage.title}>
            <PilotImageTitle>{pilotImage.title}</PilotImageTitle>
            {pilotImage.image ? (
              <>
                <PilotImage src={pilotImage.image} alt={pilotImage.alt} />
                <CrewPreview
                  onClick={() => onPreview(index)}
                  className="crew-preview"
                >
                  <Icon icon="icon-Expand" color="white" />
                </CrewPreview>
              </>
            ) : null}
          </PilotImageWrapper>
        ))}
      </Box>
    </>
  );
};

export default PilotLicense;

import { styled } from '@mui/material';

export const Header = styled('div', { name: 'CrewDetailHeader' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '24px 64px'
  })
);

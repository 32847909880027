export const fitBoundsToPolyline = (mapRef, pathCoordinates) => {
  const getMidpoint = path => {
    const latLngs = path.map(
      ({ lat, lng }) => new google.maps.LatLng(lat, lng)
    );

    // Compute the midpoint
    const midpointLatLng = google.maps.geometry.spherical.computeOffset(
      latLngs[0], // Starting point
      google.maps.geometry.spherical.computeDistanceBetween(
        latLngs[0],
        latLngs[1]
      ) / 2, // Halfway distance
      google.maps.geometry.spherical.computeHeading(latLngs[0], latLngs[1]) // Heading direction
    );

    return { lat: midpointLatLng.lat(), lng: midpointLatLng.lng() };
  };

  if (mapRef.current && pathCoordinates.length > 0) {
    const bounds = new window.google.maps.LatLngBounds();
    pathCoordinates.forEach(coord => {
      bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
    });
    bounds.extend(getMidpoint(pathCoordinates));

    mapRef.current.fitBounds(bounds, {
      top: 60, // 40px padding at the top
      bottom: 10, // 40px padding at the bottom
      left: 0, // No padding on the left
      right: 0 // No padding on the right
    });
  }
};

import { Skeleton } from '@mui/material';
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader
} from '@react-google-maps/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { fitBoundsToPolyline } from './middleware';
import { useFlightDetail } from '@flight/context/flightDetailContext';

export default function FlightMap(props: any) {
  const {
    containerStyle = {
      width: '100%',
      height: '100%'
    },
    mapData,
  } = props;
  const mapRef = useRef(null);
  const { activeLeg: activeFlight, setActiveLeg: onChange } = useFlightDetail();
  const [activePolyline, setActivePolyline] = useState(
    mapData[activeFlight].polyline
  );
  useEffect(() => {
    setActivePolyline(mapData[activeFlight].polyline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData]);

  useEffect(() => {
    setActivePolyline(mapData[activeFlight].polyline);
    fitBoundsToPolyline(mapRef, mapData[activeFlight].polyline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFlight]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDXAVt_iFRo25HnzCRBxV98UnjYrOxCyPU',
    libraries: ['geometry']
  });

  const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 0.2,
    strokeColor: '#0E5078',
    scale: 3
  };

  const lineSymbolHovered = {
    ...lineSymbol,
    strokeOpacity: 1,
    strokeColor: '#0E5078'
  };

  const isSameLocation = useMemo(() => {
    return (
      activePolyline[0].lat === activePolyline[1].lat &&
      activePolyline[0].lng === activePolyline[1].lng
    );
  }, [activePolyline]);

  const airplaneSymbol = useMemo(() => {
    if (!isLoaded || isSameLocation) return null;
    return {
      path: 'M13 40V37L17 34V23L0 28V24L17 14V3C17 2.16667 17.292 1.45867 17.876 0.876004C18.46 0.293338 19.168 0.00133788 20 4.54546e-06C20.832 -0.00132879 21.5407 0.290671 22.126 0.876004C22.7113 1.46134 23.0027 2.16934 23 3V14L40 24V28L23 23V34L27 37V40L20 38L13 40Z',
      fillColor: '#0E5078',
      fillOpacity: 0,
      scale: 1,
      strokeOpacity: 0,
      strokeColor: '#fff',
      strokeWeight: 2,
      anchor: new window.google.maps.Point(21, 30)
    };
  }, [isLoaded, isSameLocation]);

  const airplaneSymbolHovered = useMemo(() => {
    if (!isLoaded || isSameLocation) return null;
    return {
      path: 'M13 40V37L17 34V23L0 28V24L17 14V3C17 2.16667 17.292 1.45867 17.876 0.876004C18.46 0.293338 19.168 0.00133788 20 4.54546e-06C20.832 -0.00132879 21.5407 0.290671 22.126 0.876004C22.7113 1.46134 23.0027 2.16934 23 3V14L40 24V28L23 23V34L27 37V40L20 38L13 40Z',
      fillColor: '#0E5078',
      fillOpacity: 1,
      scale: 1,
      strokeOpacity: 1,
      strokeColor: '#fff',
      strokeWeight: 2,
      anchor: new window.google.maps.Point(21, 30),
      zIndex: 5
    };
  }, [isLoaded, isSameLocation]);

  const options = {
    strokeColor: '#fff',
    strokeWeight: 3,
    geodesic: true,
    strokeOpacity: 0,
    zIndex: 1,
    icons: [
      {
        icon: lineSymbol,
        offset: '0',
        repeat: '15px'
      },
      {
        icon: airplaneSymbol,
        offset: '50%'
      }
    ]
  };

  const invisibleOptions = {
    ...options,
    strokeColor: '#000', // Color won't matter as opacity is 0
    strokeOpacity: 0, // Invisible line
    strokeWeight: 15, // Make this wider to increase hover area
    clickable: true
  };

  const hoverOptions = {
    ...invisibleOptions,
    zIndex: 2,
    icons: [
      {
        icon: lineSymbolHovered,
        offset: '0',
        repeat: '15px'
      },
      {
        icon: airplaneSymbolHovered,
        offset: '50%'
      }
    ]
  };

  const handleOnLoad = map => {
    mapRef.current = map;
    fitBoundsToPolyline(mapRef, activePolyline);
  };

  const onPolylineClick = (e, path, index) => {
    fitBoundsToPolyline(mapRef, path);
    setActivePolyline(path);
    onChange(index);
  };

  const handleMouseOver = polyline => {
    polyline.setOptions(hoverOptions); // Hover style
  };

  const handleMouseOut = polyline => {
    polyline.setOptions(invisibleOptions); // Revert style
  };
  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    minZoom: 1,
    maxZoom: 10,
    restriction: {
      latLngBounds: {
        north: 85.0, // Giới hạn vĩ độ Bắc
        south: -85.0, // Giới hạn vĩ độ Nam
        east: 180.0, // Giới hạn kinh độ Đông
        west: -180.0 // Giới hạn kinh độ Tây
      },
      strictBounds: true // Nếu bạn muốn người dùng không thể ra ngoài
    }
  };

  if (!isLoaded)
    return <Skeleton variant="rounded" width="100%" height="100%" />;
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={10}
      onLoad={handleOnLoad}
      options={mapOptions}
    >
      {mapData.map((item, index) => {
        const activeArrive =
          JSON.stringify(activePolyline[1]) ===
          JSON.stringify(item.arrive_airport);
        const activeDepart =
          JSON.stringify(activePolyline[0]) ===
          JSON.stringify(item.depart_airport);
        const onePlaceFlight =
          JSON.stringify(activePolyline[1]) ===
          JSON.stringify(activePolyline[0]);
        return (
          <div key={index}>
            {/* Visible Polyline */}
            <Polyline
              key={`polyline-${index}`}
              path={item.polyline}
              options={
                activePolyline === item.polyline ? hoverOptions : options
              }
              onClick={e => onPolylineClick(e, item.polyline, index)}
            />
            {/* Invisible Polyline for Hover Detection */}
            <Polyline
              key={`invisible-polyline-${index}`}
              path={item.polyline}
              options={invisibleOptions}
              onLoad={polyline => {
                polyline.addListener('mouseover', () =>
                  handleMouseOver(polyline)
                );
                polyline.addListener('mouseout', () =>
                  handleMouseOut(polyline)
                );
              }}
              onClick={e => onPolylineClick(e, item.polyline, index)}
            />
            <Marker
              position={item.arrive_airport}
              icon={{
                path: activeArrive
                  ? 'M13.6569 2.42641C16.781 5.66162 16.781 10.9069 13.6569 14.1421L8 20L2.34315 14.1421C-0.781049 10.9069 -0.781049 5.66162 2.34315 2.42641C5.46734 -0.808802 10.5327 -0.808803 13.6569 2.42641ZM8.00007 10.6512C9.26243 10.6512 10.2858 9.59149 10.2858 8.28427C10.2858 6.97705 9.26243 5.91734 8.00007 5.91734C6.7377 5.91734 5.71436 6.97705 5.71436 8.28427C5.71436 9.59149 6.7377 10.6512 8.00007 10.6512Z'
                  : 'M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z',
                anchor: activeArrive
                  ? new window.google.maps.Point(10, 20)
                  : new window.google.maps.Point(7, 7),
                labelOrigin: new window.google.maps.Point(7, -10),
                fillColor: activeArrive ? '#E63946' : '#0E5078', // Custom color
                fillOpacity: activeArrive ? 1 : 0.3,
                strokeColor: '#FDEDEE',
                strokeOpacity: activeArrive ? 1 : 0.3,
                strokeWeight: 3,
                scale: 1.2
              }}
              label={{
                text: item.arrive_airportCode,
                className: activeArrive
                  ? 'custom-marker-label'
                  : 'custom-marker-label-hidden'
              }}
              zIndex={activeArrive ? 3 : 1}
              onClick={e => onPolylineClick(e, item.polyline, index)}
            />
            {onePlaceFlight && activeDepart ? null : (
              <Marker
                position={item.depart_airport}
                icon={{
                  path: 'M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z',
                  anchor: new window.google.maps.Point(7, 7),
                  labelOrigin: new window.google.maps.Point(7, -10),
                  fillColor: activeDepart ? '#4BB069' : '#0E5078', // Custom color
                  fillOpacity: activeDepart ? 1 : 0.3,
                  strokeColor: '#C7F8D4',
                  strokeOpacity: activeDepart ? 1 : 0.3,
                  strokeWeight: 2,
                  scale: 1
                }}
                label={{
                  text: item.depart_airportCode,
                  className: activeDepart
                    ? 'custom-marker-label'
                    : 'custom-marker-label-hidden'
                }}
                zIndex={activeDepart ? 2 : 1}
                onClick={e => onPolylineClick(e, item.polyline, index)}
              />
            )}
          </div>
        );
      })}
    </GoogleMap>
  );
}

import Icon from '@common/components/Icon';
import TruncatedText from '@common/components/TruncatedText';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const RadioGroupForm = ({ config, name, formik }: any) => {
  const [field, meta] = useField(name ?? 'RadioGroupForm');
  const {
    options,
    label,
    margin = 'normal',
    variant,
    fullWidth,
    disabled,
    labelPlacement,
    inline,
    size,
    sxWrapper
  } = config;

  const sx = inline ? { flexDirection: 'row' } : undefined;

  return (
    <FormControl
      margin={margin}
      variant={variant}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      error={meta.touched && meta.error ? true : false}
    >
      <FormLabel component="label">{label}</FormLabel>
      <RadioGroup
        aria-label={label}
        name={field.name}
        value={field.value?.toString()}
        onChange={field.onChange}
        sx={{ ...sx, ...sxWrapper }}
      >
        {options
          ? options.map((item, index) => (
              <FormControlLabel
                labelPlacement={labelPlacement}
                value={item.value?.toString()}
                disabled={disabled}
                control={<Radio size="small" />}
                label={
                  <React.Fragment>
                    <Box className="flex-center-rows">
                      <TruncatedText variant="body1" color="neutral.dark3">
                        {item.label}
                      </TruncatedText>
                      {item.hint ? (
                        <Tooltip
                          arrow
                          title={item.hint}
                          onClick={e => {
                            e.preventDefault();
                          }}
                        >
                          <Icon
                            icon="icon-question-fill"
                            color="inherit"
                            sx={{
                              color: 'primary.main',
                              ml: 0.5
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </Box>
                  </React.Fragment>
                }
                key={index.toString()}
              />
            ))
          : null}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupForm;

import { Box, Grid, Typography } from '@mui/material';
import Footer from '../ActionFooter';
import LuggageItem from './LuggageItem';
import { Wrapper } from './styled';
import { useRef } from 'react';
import useFadeScroll from 'src/hooks/useFadedScroll';

export default function PassengerLuggage(props: any) {
  const { closeDialog, cancelButtonLabel, passenger, totalLuggage } = props;
  const ref = useRef(null);
  useFadeScroll({ scrollRef: ref, size: 'small' });
  return (
    <>
      <Wrapper sx={{ width: 600 }}>
        <Grid container>
          <Grid container item sx={{ px: 2 }}>
            <Grid item xs={6}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Name & type
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Checked in
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Loaded
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography color="neutral.dark1" variant="subtitle2" noWrap>
                Weight
              </Typography>
            </Grid>
          </Grid>

          <Box
            ref={ref}
            sx={{
              mt: 2,
              width: '100%',
              maxHeight: '400px',
              overflow: 'auto',
              borderRadius: '4px'
            }}
          >
            {passenger.map((item: Record<string, any>, index: number) => {
              return (
                <LuggageItem
                  customer={item.customer}
                  key={index}
                  index={index}
                />
              );
            })}
          </Box>
        </Grid>
        <Grid container item sx={{ px: 2, mt: 2 }}>
          <Grid item xs={10.5} sx={{ justifyContent: 'flex-end' }}>
            <Typography color="neutral.main" variant="subtitle3" noWrap>
              {`Total weight: `}&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography color="primary.dark2" variant="subtitle1" noWrap>
              <b>{totalLuggage} kg</b>
            </Typography>
          </Grid>
        </Grid>

        <Footer
          cancelButtonLabel={cancelButtonLabel}
          closeDialog={closeDialog}
        />
      </Wrapper>
    </>
  );
}

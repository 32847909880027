import Icon from '@common/components/Icon';
import { Grid, Skeleton, Typography } from '@mui/material';
import {
  AvatarWrapper,
  CrewItemWrapper,
  MenuWrapper,
  TableWrapper
} from './styled';
const CrewTableItemSkeleton = () => {
  const tableCells = [
    { sx: { pl: 2.5 }, xl: (120 / 1104) * 12, xs: 1 },
    { xs: 3.5, xl: (520 / 1104) * 12 },
    { xl: (180 / 1104) * 12, xs: 3 },
    { xs: 3, xl: (180 / 1104) * 12 },
    { xl: (96 / 1104) * 12, xs: 1 }
  ];

  return (
    <CrewItemWrapper>
      <AvatarWrapper>
        <Skeleton
          variant="rectangular"
          width={64}
          height={64}
          className="avatar"
        />
      </AvatarWrapper>
      <TableWrapper>
        <Grid container>
          {tableCells.map((cell, index) => (
            <Grid sx={cell.sx} item xs={cell.xl} key={index}>
              <Skeleton variant="text" width={50} />
            </Grid>
          ))}
          <Grid item xs={'auto'}>
            <Typography color="neutral.dark1" variant="subtitle2" />
          </Grid>
        </Grid>
      </TableWrapper>
      <MenuWrapper>
        <Icon icon="icon-dot-menu" sx={{ color: 'neutral.dark1' }} size="sm" />
      </MenuWrapper>
    </CrewItemWrapper>
  );
};

export default CrewTableItemSkeleton;

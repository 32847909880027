import TextFieldForm from '@common/formElement/TextFieldForm';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../ActionFooter';
import { Wrapper } from './styled';
import { InviteCrewSchema } from '@crew/helper/schema';
import { SelectForm } from '@common/formElement';

export default function InviteCrewDialog(props: any) {
  const { closeDialog, cancelButtonLabel, submitButtonLabel, setForceOpen } =
    props;
  const [isSubmitting, setSubmitting] = useState(false);

  const menus = [
    {
      value: 1,
      label: 'Captain'
    },
    {
      value: 2,
      label: 'First Officer'
    },
    {
      value: 3,
      label: 'Cabin Manager'
    },
    {
      value: 4,
      label: 'Flight Attendant'
    },
    {
      value: 5,
      label: 'Supernumerary'
    }
  ];

  useEffect(() => {
    setForceOpen(isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const dispatch = useDispatch();
  const handleSubmit = (value: any, formik: FormikHelpers<any>) => {
    dispatch({
      type: '@crew/inviteCrew',
      payload: { data: value },
      meta: { setSubmitting, closeDialog, setFieldError: formik.setFieldError }
    });
  };

  return (
    <Wrapper>
      <Typography variant="body1" color="neutral.dark3">
        Please enter an email address below.
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            crewTypeId: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={InviteCrewSchema}
          validateOnMount
        >
          {({ initialValues, isValid }) => (
            <Form>
              <TextFieldForm
                name="email"
                config={{
                  label: 'Email address',
                  fullWidth: true,
                  required: true
                }}
                initialValues={initialValues}
              />
              <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="firstName"
                      config={{
                        label: 'First name (optional)',
                        fullWidth: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldForm
                      name="lastName"
                      config={{
                        label: 'Last name (optional)',
                        fullWidth: true
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 0 }}>
                  <Grid item xs={12}>
                    <SelectForm
                      name="crewTypeId"
                      config={{
                        label: 'Select crew type',
                        fullWidth: true,
                        required: true,
                        options: menus
                      }}
                      initialValues={initialValues}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Footer
                cancelButtonLabel={cancelButtonLabel}
                submitButtonLabel={submitButtonLabel}
                closeDialog={closeDialog}
                disabledSubmit={!isValid || isSubmitting}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Wrapper>
  );
}

import { useParams } from 'react-router';
import useFetchDetail from 'src/hooks/useFetchDetail';
import { Body, CrewDetailWrapper, CrewHeader } from './styled';
import CrewDetailHeader from '@crew/components/crew-detail/Header';
import { CrewBanner, CrewContent } from '@crew/components/crew-detail';
import LoadingProgress from '@common/components/LoadingProgress';

const CrewDetail = () => {
  const { id } = useParams();

  const [data, loading] = useFetchDetail('crew', '/verify/crews/:id', parseInt(id));

  return loading ? (
    <LoadingProgress />
  ) : (
    <CrewDetailWrapper>
      <CrewDetailHeader />
      <Body sx={{ px: 8, display: 'flex', flexDirection: 'column' }}>
        <CrewHeader>Crew details</CrewHeader>
        <CrewBanner data={data} />
        <CrewContent data={data} />
      </Body>
    </CrewDetailWrapper>
  );
};

export default CrewDetail;

import { DialogJSX } from '@utils/dialog/DialogUtils';
import { DialogProvider } from '@utils/dialog/dialogProvider';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
let globalDialogRef: any;

export default function DialogContext() {
  useEffect(() => {
    DialogJSX.registerDialog(globalDialogRef);
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    DialogJSX.close('all');
  }, [pathname]);
  return (
    <>
      <DialogProvider ref={ref => (globalDialogRef = ref)} />
      <Outlet />
    </>
  );
}

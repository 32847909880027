import Icon from '@common/components/Icon';
import { Button, Typography } from '@mui/material';
import { Wrapper } from './styled';
import { useDispatch } from 'react-redux';

export default function RegisterSuccessDialog(props: any) {
  const dispatch = useDispatch();
  const goSignIn = () => {
    dispatch({ type: '@logout' });
  };

  return (
    <>
      <Wrapper className="flex-center-columns">
        <Icon
          icon="icon-check-circle-fill"
          color="positive"
          sx={{ fontSize: '48px', mb: 3 }}
        />
        <Typography variant="h6" color="neutral.dark3">
          Thank you
        </Typography>
        <Typography
          variant="body1"
          color="neutral.dark3"
          component="div"
          sx={{ mt: 1, mb: 3 }}
        >
          You have completed registration.
        </Typography>
        <Button onClick={goSignIn} color="secondary" variant="contained">
          Go to sign-in
        </Button>
      </Wrapper>
    </>
  );
}

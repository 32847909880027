import Icon from '@common/components/Icon';
import TruncatedText from '@common/components/TruncatedText';
import { getName } from '@helper/stringFunction';
import { Box, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import React from 'react';
const Checked = () => (
  <Icon
    icon="icon-check-circle-fill"
    size="md"
    sx={{
      color: 'positive.dark1'
    }}
  />
);

const UnChecked = () => (
  <Icon
    icon="icon-X-circle-filled"
    size="md"
    sx={{
      color: 'neutral.main'
    }}
  />
);

export default function LuggageItem({
  customer,
  index
}: {
  customer: Record<string, any>;
  index: number;
}) {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: index % 2 === 0 ? 'primary.light2' : 'primary.light3'
      }}
    >
      <TruncatedText variant="subtitle3" color="primary.dark3">
        {getName(customer)}
      </TruncatedText>
      <Grid container rowSpacing={1} sx={{ mt: 0 }}>
        {customer.luggage.map((item: Record<string, any>, index: number) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <Typography color="neutral.dark3" variant="body2" noWrap>
                  {get(item, 'type.luggage_name')}
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                {item.checkedIn ? <Checked /> : <UnChecked />}
              </Grid>
              <Grid item xs={2}>
                {item.loaded ? <Checked /> : <UnChecked />}
              </Grid>
              <Grid item xs={1.5}>
                <Typography color="neutral.dark3" variant="body2" noWrap>
                  {item.weight} kg
                </Typography>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
}
